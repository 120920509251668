import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationForm } from '@base/core';
import { useGetMultipleForms } from '../../campaign/hooks/useGetMultipleForms';
import { useGetGroup } from '../../users/hooks/useGetGroup';
import { FormsExternal } from '../FormsExternal';
import { useGetMultipleFormSubmissions } from '../hooks/useGetMultipleFormSubmissions';
import { getDeadlineDateFromForm } from './getDeadlineDateFromForm';

export function FomsubmissionsExternal({ match }) {
  const { groupId } = useParams<{ groupId: string }>();

  const { data: group } = useGetGroup(groupId);
  const requiredForms = Object.entries(group?.formSubmissions ?? {}).map(([formId, campaignId]: [string, string]) => ({ campaignId, formId }));

  const forms = useGetMultipleForms(requiredForms);
  const submissionData = useGetMultipleFormSubmissions(requiredForms);

  const finalForms = useMemo(() => {
    const filteredForms: ApplicationForm[] = forms
      .filter((d) => d.data)
      .map((d) => d.data)
      .map((f: ApplicationForm) => {
        const queryData = submissionData.find((q) => q.data?.formId == f.id);


        return { ...f, status: queryData?.data?.state ?? 'not-started', deadline: getDeadlineDateFromForm(f.deadline), submission: queryData?.data } as any;
      })
      .sort((a, b) => b.submission?.submissionDate - a.submission?.submissionDate)

    return filteredForms.map((f) => ({
      ...f,
      reports: (f as any).reports.map((r: ApplicationForm) => {
        const queryData = submissionData.find((q) => q.data?.formId == r.id);
        return {
          ...r,
          status: queryData?.data?.state ?? 'not-started',
          deadline: getDeadlineDateFromForm(r.deadline),
          submission: queryData?.data,
        } as any;
      }),
    }));
  }, [forms, submissionData]);

  const history = useHistory();

  return (
    <FormsExternal
      parents={[]}
      forms={finalForms as any}
      baseUrl={match.url}
      onFormDoubleClick={(formId: string, campaignId?: string) => {
        history.push('/campaigns-external/' + campaignId + '/forms/' + formId);
      }}
      withFilters={true}
    />
  );
}
