import { Campaign } from '@base/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetGroups } from '../../users/hooks/useGetGroups';
import { CampaignManager } from '../CampaignManager';
import { getCurrentBiathlonYear } from '../getCurrentBiathlonYear';
import { useCreateCampaignMutation, useDeepCopyCampaignMutation, useDeleteFileMutation, useMovecampaignsMutation } from '../hooks/mutations';
import { useGetChildren } from '../hooks/useFolderChildren';
import { useGetFile } from '../hooks/useGetFile';
import { useGetFormSubmissions } from '../hooks/useGetFormSubmissions';

export function CampaignManagerWrapper({ match }) {
  const { parentId } = useParams<{ parentId: string }>();

  const { data, isLoading } = useGetChildren<Campaign[]>(parentId);
  const { data: parent } = useGetFile(parentId);
  const { data: groups = [] } = useGetGroups();

  const history = useHistory();

  const createMutation = useCreateCampaignMutation();
  const moveMutation = useMovecampaignsMutation();
  const deleteMutation = useDeleteFileMutation();
  const copyMutation = useDeepCopyCampaignMutation();

  const createCampaign = async (name: string, description: string, biathlonYear: string) => {
    await createMutation.mutateAsync({
      parent: parentId,
      name,
      description,
      biathlonYear: biathlonYear
    } as any);
  };

  return (
    <CampaignManager
      groups={groups}
      onMoveCampaign={(campaign, parent) => moveMutation.mutateAsync([{ fileId: campaign.id, oldParent: campaign.parent, parent }])}
      onDeleteCampaign={(campaign) => deleteMutation.mutateAsync(campaign)}
      onDuplicateCampaign={async (campaign, parent) => {
        const { releaseDate, publishedState, deadline, biathlonYear, ...clearedCampaign } = campaign
        await copyMutation.mutateAsync({ campaign: { ...clearedCampaign, biathlonYear: getCurrentBiathlonYear() } as any, newParent: parent });
      }}
      onDuplicateCampaignToYear={async (campaign, newBiathlonYear, parent) => {
        const { releaseDate, publishedState, deadline, biathlonYear, ...clearedCampaign } = campaign
        await copyMutation.mutateAsync({ campaign: { ...clearedCampaign, biathlonYear: newBiathlonYear } as any, newParent: parent });
      }}
      campaigns={data?.children ?? []}
      loading={isLoading}
      onCreateCampaign={createCampaign}
      baseUrl={'/campaigns/'}
      parents={parent ? [parent] : []}
      onCampaignDoubleClick={(c) => {
        history.push(c.id);
      }}
    />
  );
}
