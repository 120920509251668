import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export function getSchema(t: TFunction<'translation'>) {
  return yup.object().shape({
    email: yup
      .string()
      .email(t('enter-a-valid-email'))
      .required(t('something-is-required', { something: t('email') })),
    displayName: yup.string().required(t('something-is-required', { something: t('name') })),
    phoneNumber: yup.string().required(t('something-is-required', { something: t('phone-number') })),
    address: yup.string().required(t('something-is-required', { something: t('address') })),
    zipCode: yup.string().required(t('something-is-required', { something: t('zip-code') })),
    country: yup.string().required(t('something-is-required', { something: t('country') })),
    organizationName: yup.string().required(t('something-is-required', { something: t('name-of-sp') })),
    position: yup.string(),
  });
}
