import { AccessRequestFormData } from '@base/core';

export function getInitialValues<T extends AccessRequestFormData>(formData?: T): T {
  return {
    email: formData?.email ?? '',
    phoneNumber: formData?.phoneNumber ?? '',
    displayName: formData?.displayName ?? '',
    address: formData?.address ?? '',
    zipCode: formData?.zipCode ?? '',
    country: formData?.country ?? '',
    organizationName: formData?.organizationName ?? '',
    position: formData?.position ?? '',
  } as any;
}
