import { API, ApplicationForm, Campaign, FormSubmission, Group } from '@base/core';
import { Form, getBudgetFromDocument } from '@editors/form-editor';
import { faCommentLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Drawer, ListItemIcon, ListItemText, MenuItem, Select, Stack, Typography, useTheme } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { combineLatest } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { map, switchMap } from 'rxjs/operators';
import { useSettings } from '../../theme/ThemeProvider';
import { useUpdateFormContentMutation } from '../externalUsers/hooks/mutations';
import { useAutoUpdateState } from '../fileManager/useAutoUpdateState';
import { Kanban } from '../kanban/Kanban';
import { FormSubmissionDrawer } from './FormSubmissionDrawer';
import { useGetFormSubmissions } from './hooks/useGetFormSubmissions';
import { sendAcceptedNotification } from './sendAcceptedNotification';
import { ComparePopup } from './ComparePopup';
import { getDeadlineDateFromForm } from '../externalUsers/router/getDeadlineDateFromForm';

function useGetUnreadCount(me, submissions: any[]) {
  return useObservable<{ [formId: string]: number }, [FormSubmission[]]>(
    (state$, input$) =>
      input$.pipe(
        switchMap(([submissions]) => {
          return combineLatest(
            submissions.map((s) => {
              const chatId = 'formsubmissions:' + s.id;
              return API.chats.getChatObservable(chatId).pipe(
                map((chatItems) => ({
                  formId: s.formId,
                  chats: chatItems,
                })),
              );
            }),
          );
        }),
        map((chats) => {
          return chats.reduce((map, chatInfo) => {
            const unreadCount = chatInfo.chats.reduce((acc, chat) => acc + (chat.read[me.id] ? 0 : 1), 0);
            if (map[chatInfo.formId]) map[chatInfo.formId] += unreadCount;
            else map[chatInfo.formId] = unreadCount;
            return map;
          }, {});
        }),
      ),
    {},
    [submissions],
  );
}

export function FormSubmissionsByNF({ campaign, forms, groups }: { campaign: Campaign; forms: ApplicationForm[]; groups: Group[] }) {
  const { data: submissions = [] } = useGetFormSubmissions(campaign.id);
  const history = useHistory();
  const theme = useTheme();

  const updateSubmissionMutation = useUpdateFormContentMutation();

  const settings = useSettings();
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<string>(null);
  const [selectedGroupId, setSelectedGroupId] = useAutoUpdateState(submissions[0]?.entityId ?? null, [submissions.length === 0]);
  console.log('🚀 ~ file: FormSubmissionsByNF.tsx ~ line 32 ~ FormSubmissionsByNF ~ selectedGroupId', selectedGroupId);
  const me = useSelector((state: Core.StateType) => state.auth.user);

  const availableGroups = useMemo<{ [key: string]: Group }>(
    () => Object.fromEntries(submissions.map((submission) => [submission.entityId, groups.find((group) => group.id == submission.entityId)]).filter((_, group) => group)),
    [submissions, groups],
  );

  const unreadCount = useGetUnreadCount(me, submissions);
  const [comparePopupOpen, setComparePopupOpen] = useState(false);

  const selectedSubmission = submissions.find((s) => s.id === selectedSubmissionId);
  const formIdMap = useMemo(() => Object.fromEntries(forms.map((form) => [form.id, form])), [forms]);
  const selectedForm = formIdMap[selectedSubmission?.formId] as ApplicationForm;
  const groupsWithSubmission = useMemo<Group[]>(() => {
    const groupIdsInSubmissions = submissions.map((submission) => submission.entityId);
    return groups.filter((group) => groupIdsInSubmissions.includes(group.id));
  }, [groups, submissions]);

  const [selectedSubmissions, setSelectedSubmissions] = useState<string[]>([]);
  return (
    <>
    <Drawer PaperProps={{ sx: { width: 550 } }} anchor="right" open={Boolean(selectedSubmissionId)} onClose={() => setSelectedSubmissionId(null)}>
        {selectedForm && <FormSubmissionDrawer groups={groupsWithSubmission} content={selectedSubmission} form={selectedForm} onClose={() => setSelectedSubmissionId(null)} />}
        {!selectedForm && (
          <Typography sx={{ m: 3 }} color="error">
            Form has been deleted
          </Typography>
        )}
      </Drawer>
      <ComparePopup
        open={comparePopupOpen}
        onClose={() => setComparePopupOpen(false)}
        selectedSubmissions={selectedSubmissions.map((id) => id.split(':')[0])}
        submissionData={submissions.filter((s) => s.entityId === selectedGroupId && s)}
        group={groups.find((g) => g.id === selectedGroupId)}
        finalForms={forms}
      />
      <Stack display="flex" flex={1} paddingTop={3.5} paddingRight={4} paddingLeft={1} spacing={2} overflow={"hidden"}>
        <Stack direction="row" spacing={2} marginBottom={6} alignItems="center">
          <Select value={selectedGroupId} onChange={(e) => setSelectedGroupId(e.target.value)} variant={'outlined'}>
          {Object.values(availableGroups)
            .filter((g) => g)
            .map((group) => (
              <MenuItem key={group.id} value={group.id}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1} maxHeight={"52vw"} overflow={"auto"}>
                  <ListItemText>{group.name}</ListItemText>
                  <ListItemIcon>
                    <Box
                      sx={{
                        minWidth: 16,
                        height: 16,
                        backgroundColor: theme.palette.error.main,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 50,
                        padding: 0.4,
                        paddingX: 1,
                        marginRight: -2,
                        marginLeft: 1,
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '600',
                        visibility: unreadCount[group.id] ? undefined : 'hidden',
                      }}
                    >
                      <Typography variant="subtitle2">
                        <FontAwesomeIcon style={{ marginRight: 6 }} icon={faCommentLines} />
                        {unreadCount[group.id]}
                      </Typography>
                    </Box>
                  </ListItemIcon>
                </Stack>
              </MenuItem>
            ))}
        </Select>
        <Box sx={{ flex: 1 }} />
        <Button variant="contained" disabled={selectedSubmissions.length == 0} onClick={() => setComparePopupOpen(true)}>
          Compare
        </Button>
      </Stack>
      <div style={{display: "flex", maxHeight: `calc(100vh - 400px)`, width: "100%", overflow: "auto", paddingLeft:16, paddingRight:16}}>
        <Kanban
          submissions={submissions
            .filter((s) => s.entityId === selectedGroupId && s)
            .map((submission) => {
              const group = groups.find((g) => g.id === submission.entityId);
              if (!group) return null;
              return {
                name: formIdMap[submission.formId]?.name,
                icon: settings.namedGroups.find((n) => n.name === group.type).getIcon?.(group),
                id: submission.id,
                groupId: submission.entityId,
                budget: formIdMap[submission.formId] && getBudgetFromDocument(formIdMap[submission.formId], submission.data),
                ...submission,
              };
            })
            .filter((g) => g)}
          onItemClick={(item) => {
            console.log('clicked:', item);
            setSelectedSubmissionId(item.id);
          }}
          onSelectionChange={setSelectedSubmissions}
          selection={selectedSubmissions}
          onStatusChange={async (status, item) => {
            await updateSubmissionMutation.mutateAsync({
              campaignId: campaign.id,
              formId: item.formId,
              groupId: item.groupId,
              data: {state: status as any}
            });

            if (status === 'success' || status === 'rejected') {
              await sendAcceptedNotification(item.entityId, status === 'success' ? 'accept' : 'reject', undefined, item.campaignId, item.formId, item.name);
            }
          }}
        />
      </div>
    </Stack>
    </>
  );
}
