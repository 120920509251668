import { SigningParticipantType } from '@base/core';
import { createCallableApi } from './createCallableApi';

/**
 * Don't forget the correct Content-Type in additional headers
 * */
async function callAdobeApi<T>(
  apiUrl: string,
  apiPath: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD',
  authToken: string | undefined,
  data: any,
  additionalHeaders?: any,
  nonJsonResponse = false
) {
  let headers: any = {
    Accept: 'application/json',
  };

  if (additionalHeaders) {
    headers = { ...headers, ...additionalHeaders };
  }
  if (authToken) headers['Authorization'] = `Bearer ${authToken}`;

  const payload = {
    headers,
    method,
  };

  if (method !== 'GET' && method !== 'HEAD') {
    payload['body'] = data;
  }

  const res = await fetch(apiUrl + apiPath, payload);
  if (!res.ok) throw new Error(`HTTP ERROR ${res.status} - ${res.statusText}`);
  if (nonJsonResponse) return (res as unknown) as T;
  return (await res.json()) as T;
}

const clientId = 'CBJCHBCAABAA1yorAloybhjpoK9o3hY7yVSSF1AZXc9I'; //-- original
// const clientId = 'CBJCHBCAABAAy78I-e43Ye2J8qxe7t6tGerIDc79Pcni'; // dev account
const baseUrisEndpoint = 'https://api.echosign.com/api/rest/v6';

export class AdobeSignService {
  private currentAuthToken: string;
  private currentAuthTokenExpiration: number;
  private currentWebEndpoint: string;
  private currentApiEndpoint: string;

  checkIfUserAuthenticated(): boolean {
    console.log('Adobe sign Authentication status: ', this.currentAuthToken, this.currentAuthTokenExpiration, Date.now());
    return Boolean(this.currentAuthTokenExpiration && this.currentAuthTokenExpiration > Date.now() && this.currentAuthToken);
  }

  async getOAuthToken(uid: string): Promise<string> {
    // if currentAuth token expired get new one else return current one
    if (this.currentAuthTokenExpiration && this.currentAuthTokenExpiration > Date.now() && this.currentAuthToken) {
      return this.currentAuthToken;
    }
    // request a new token from the server
    // if the refresh token is expired or not existing, this method will throw an error
    const response = await createCallableApi<{
      accessToken: string;
      expiresIn: number;
    }>('/sign/access', 'GET', {
      uid: uid,
    })();

    const token = response.data.accessToken;
    this.currentAuthToken = token;
    this.currentAuthTokenExpiration = Date.now() + response.data.expiresIn * 1000;

    return token;
  }

  async redirectUserToLogin(redirectUrl: string, uid: string, state?: string): Promise<void> {
    const oAuthUrl = `https://secure.eu1.adobesign.com/public/oauth/v2`;
    const redirectUriParam = `redirect_uri=${redirectUrl}`;
    const responseTypeParam = `response_type=code`;
    const clientIdParam = `client_id=${clientId}`;
    const scopeParam = `scope=user_login:self+agreement_write:self+agreement_read:self+workflow_read:self+workflow_write:self`;
    const stateParam = `state=${state}%20${redirectUrl}&redirect_uri=${redirectUrl}`;

    const apiPath = '?' + redirectUriParam + '&' + responseTypeParam + '&' + clientIdParam + '&' + scopeParam + '&' + stateParam;

    // redirect to the Adobe Sign OAuth page
    window.location.href = oAuthUrl + apiPath;
    // code after this may not be executed - user is redirected to the Adobe Sign OAuth page
  }

  async processAuthCode(code: string, redirectUrl: string, uid: string): Promise<void> {
    console.log('Process Adobe Auth code', code, redirectUrl, uid);

    const response = await createCallableApi<{
      accessToken: string;
      apiEndpoint: string;
      webEndpoint: string;
      expiresIn: number;
    }>(
      '/sign/authenticate',
      'POST'
    )({
      authCode: code,
      redirectUrl: redirectUrl,
      uid: uid,
    });

    this.currentAuthToken = response.data.accessToken;
    this.currentWebEndpoint = response.data.webEndpoint;
    this.currentApiEndpoint = response.data.apiEndpoint;
    this.currentAuthTokenExpiration = Date.now() + response.data.expiresIn * 1000; // seconds to milliseconds
  }

  async getApiEndpoint(): Promise<{ webApiAccessPoint: string; apiAccessPoint: string }> {
    const baseUrisResponse = await callAdobeApi<{
      apiAccessPoint: string;
      webAccessPoint: string;
    }>(baseUrisEndpoint, `/baseUris`, 'GET', this.currentAuthToken, undefined, {
      'Content-Type': 'application/json',
    });

    console.log('Base URI Response: ', baseUrisResponse);

    this.currentWebEndpoint = baseUrisResponse.webAccessPoint;
    this.currentApiEndpoint = baseUrisResponse.apiAccessPoint;

    return { webApiAccessPoint: baseUrisResponse.webAccessPoint, apiAccessPoint: baseUrisResponse.apiAccessPoint };
  }

  async uploadTransientDocument(file: File): Promise<string> {
    const apiPath = 'api/rest/v6/transientDocuments';
    const formData = new FormData();
    formData.append('File-Name', file.name);
    formData.append('Mime-Type', file.type);
    formData.append('File', file, file.name);

    const response = await callAdobeApi<{
      transientDocumentId: string;
    }>(this.currentApiEndpoint, apiPath, 'POST', this.currentAuthToken, formData, {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Accept-Encoding': 'gzip, deflate, br',
    });

    return response.transientDocumentId;
  }

  async sendUploadedDocumentToSign(agreementProps: { docId: string; name: string; recipients: SigningParticipantType[]; externalId: string; deadline?: Date; sendDraft?: boolean }): Promise<string> {
    const apiPath = '/api/rest/v6/agreements';

    const transformedParticipants = agreementProps.recipients.map((recipient, index) => {
      return { role: 'SIGNER', memberInfos: [{ email: recipient.email, name: recipient.name }], order: index + 1 };
    });

    const body: any = {
      fileInfos: [{ transientDocumentId: agreementProps.docId }],
      name: agreementProps.name,
      participantSetsInfo: transformedParticipants,
      signatureType: 'ESIGN',
      state: agreementProps.sendDraft ? 'AUTHORING' : 'IN_PROCESS', // use "IN_PROCESS" to send the document immediately or "DRAFT" to make changes before sending
      locale: 'en_US',
      reminderFrequency: 'WEEKLY_UNTIL_SIGNED',
      status: agreementProps.sendDraft ? 'DRAFT' : 'OUT_FOR_SIGNATURE',
      type: 'AGREEMENT',
      externalId: agreementProps.externalId,
      postSignOption: { redirectUrl: 'https://www.ibu-scope.com', redirectDelay: 10 }, // redirect the user to the ibu scope after signing
    };

    if (agreementProps.deadline) {
      const deadlineDate = new Date(agreementProps.deadline);
      const year = deadlineDate.getFullYear();
      const month = lpad(deadlineDate.getMonth() + 1);
      const day = lpad(deadlineDate.getDate());
      const hour = lpad(deadlineDate.getHours());
      const minute = lpad(deadlineDate.getMinutes());
      const second = lpad(deadlineDate.getSeconds());
      const date = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
      body.expirationTime = date;
    }
    const response = await callAdobeApi<{ id: string }>(this.currentApiEndpoint, apiPath, 'POST', this.currentAuthToken, JSON.stringify(body), {
      'Content-Type': 'application/json',
    });

    return response.id; // agreement id
  }

  // not implemented due to the recievment of the adobe email is stated as an act of authentication
  // async getDocumentSigningUrl(agreementId: string): Promise<SigningUrlType[]> {
  //   const apiPath = '/api/rest/v6/agreements/' + agreementId + '/signingUrls';
  //
  //   const response = await callAdobeApi<{
  //     signingUrlSetInfos: {
  //       signingUrls: {
  //         email: string;
  //         esignUrl: string;
  //       }[];
  //     }[];
  //   }>(this.currentApiEndpoint, apiPath, 'GET', this.currentAuthToken, {});
  //
  //   return response.data.signingUrlSetInfos;
  // }

  async getSignedDocDownloadUrl(agreementId: string): Promise<string> {
    const apiPath = '/api/rest/v6/agreements/' + agreementId + '/combinedDocument' + '?attachSupportingDocuments=true&attachAuditReport=true';

    // adobe upload take some time
    await new Promise(
      resolve => setTimeout(resolve, 2000)
    )

    const response = await callAdobeApi<{
      body: ReadableStream;
    }>(
      this.currentApiEndpoint,
      apiPath,
      'GET',
      this.currentAuthToken,
      {},
      {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      true
    );

    const reader = response.body.getReader();
    const readableStream = new ReadableStream({
      start(controller) {
        return pump();

        function pump() {
          return reader.read().then(({ done, value }) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close();
              return;
            }
            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            return pump();
          });
        }
      },
    });
    // Create a new response out of the stream
    const file = await new Response(readableStream).blob();
    // Create an object URL for the response
    const blob = new Blob([file], { type: 'application/pdf' });
    const objectURL = window.URL.createObjectURL(blob);

    return objectURL;
  }

  async abortAgreement(agreementId: string): Promise<void> {
    const cancelPath = '/api/rest/v6/agreements/' + agreementId + '/state';
    // const deletePath = "/api/rest/v6/agreements/" + agreementId + "/documents";

    const body1 = {
      state: 'CANCELLED',
      agreementCancellationInfo: {
        notifyOthers: true,
      },
    };

    const cancelResponse = await callAdobeApi(this.currentApiEndpoint, cancelPath, 'PUT', this.currentAuthToken, JSON.stringify(body1), {
      'Content-Type': 'application/json',
    });

    // "The operation requires some account settings to be enabled. Please contact the Acrobat Sign team to enable the settings"... seems like the following needs other permissions on the system
    // const deleteResponse = await callAdobeApi(this.currentApiEndpoint, deletePath, 'DELETE', this.currentAuthToken, undefined,
    //   {"Content-Type": "application/json"});
  }
}

// Helpers
function lpad(value: number, count = 2) {
  let padded = value.toString();
  while (padded.length < count) {
    padded = '0' + padded;
  }
  return padded;
}
