import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, useTheme } from '@material-ui/core';
import { DatePicker, LoadingButton } from '@material-ui/lab';
import { Field, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { StandardPopup, usePopup } from '../../components/common';
import {BiathlonYearPicker} from "./settings/BiathlonYearPicker";
import {getCurrentBiathlonYear} from "./getCurrentBiathlonYear";

interface CampaignCreationPopupProps {
  open?: boolean;
  onClose(): void;
  onCreate(name: string, description: string, biathlonYear: string): Promise<void> | void;
}

export function CampaignCreationPopup({ onClose, open = false, onCreate }: CampaignCreationPopupProps) {
  const theme = useTheme();
  const notify = usePopup();
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [seasonValue, setSeasonValue] = React.useState<string>(getCurrentBiathlonYear());

  const schema = yup.object({
    name: yup.string().required(t('something-is-required', { something: t('name') })),
    description: yup.string(),
  });

  return (
    <StandardPopup visible={open} onBackdropClick={onClose}>
      <Formik
        initialValues={{ name: '', description: '' }}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await onCreate(values.name, values.description, seasonValue);
            onClose();
          } catch (error) {
            notify({
              title: t('error'),
              type: 'error',
              text: error.message,
            });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, errors, touched, isSubmitting }) => (
          <Card>
            <CardHeader
              title={t('create-new-campaign')}
              titleTypographyProps={{ variant: 'h2' }}
              style={{ background: theme.palette.background.gray, paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Field as={TextField} name="name" label={t('name')} fullWidth variant="outlined" required error={touched.name && Boolean(errors.name)} helperText={touched.name && errors.name} />
                </Grid>
                <Grid xs={12} item>
                  <Field
                    as={TextField}
                    name="description"
                    label={t('description')}
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={5}
                    maxRows={5}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid xs={12} item>
                  <DatePicker
                    label={t('deadline')}
                    views={['day']}
                    disablePast
                    mask={t('full-date-mask')}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" />}
                  />
                </Grid>
                <Grid xs={12} item>
                  <BiathlonYearPicker
                    sx={{ width: 150 }}
                    value={seasonValue}
                    onChange={(e) => {
                      setSeasonValue(e.target.value as string);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ paddingX: 3, paddingBottom: 3, justifyContent: 'flex-end' }}>
              <Button variant="outlined" color="error" onClick={onClose}>
                {t('cancel')}
              </Button>
              <LoadingButton startIcon={<FontAwesomeIcon icon={faPlusCircle} />} variant="contained" onClick={handleSubmit as any} loading={isSubmitting}>
                {t('create')}
              </LoadingButton>
            </CardActions>
          </Card>
        )}
      </Formik>
    </StandardPopup>
  );
}
