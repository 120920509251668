import { Actions, API } from '@base/core';
import { faBan, faPlusCircle, faSave, faTrash, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { LoadingPromiseButton } from '../../components';
import { ConditionalRender, StandardPopup, useNotificationEffect, usePopup } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import '../../css/Views/admin/users/UsersOverride.scss';
import { ConditionalRenderIfOwn, ConditionalRenderWithRights, useWithRights } from '../../helpers/ConditionalRenderWithRights';
// import { useTheme } from '../../theme/ThemeProvider';
import { useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation } from './hooks/mutations';
import { UserEditAdminContent } from './UserEditAdminContent';
import { UserEditUserContent } from './UserEditUserContent';

function removeUndefined<T extends object>(val: T): T {
  if (!val) return val;
  for (const key of Object.keys(val)) {
    //@ts-ignore
    if (val[key] == undefined)
      //@ts-ignore
      delete val[key];
  }
  return val;
}

export const UserEdit = ({ currentUser, onClose, open }: { currentUser?: Core.User; onClose: () => void; open: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const compareStateUser = currentUser;
  const notification = usePopup();

  const userEditsHimself = user && user.id === currentUser?.id;

  const userUpdateMutation = useUpdateUserMutation();
  const userDeleteMutation = useDeleteUserMutation();
  const userCreateMutation = useCreateUserMutation();

  useNotificationEffect({ title: t('error'), type: 'error', text: userCreateMutation.error?.message }, userCreateMutation.isError, [userCreateMutation.error]);
  useNotificationEffect({ title: t('error'), type: 'error', text: userUpdateMutation.error?.message }, userUpdateMutation.isError, [userUpdateMutation.error]);
  useNotificationEffect({ title: t('error'), type: 'error', text: userDeleteMutation.error?.message }, userDeleteMutation.isError, [userDeleteMutation.error]);
  const [openDeletePopup, setOpenDeletePopup] = useState(undefined);

  const deleteUser = async () => {
    await userDeleteMutation.mutateAsync(currentUser.id);
    onClose();
  };

  const updateUser = async ({ newPassword: password, rights, roles, ...update }: Core.User & { newPassword?: string; userImage: Blob }) => {
    let userUpdate: Core.User;

    Object.entries(update).forEach((val) => {
      if (compareStateUser[val[0]] !== val[1]) {
        userUpdate = { ...userUpdate, [val[0]]: val[1] };
      }
    });

    if (compareStateUser.rights !== rights) {
      userUpdate = { ...userUpdate, rights: rights };
      await dispatch(Actions().users.setUserRights(currentUser.id, rights));
    }

    if (compareStateUser.roles !== roles) {
      await dispatch(Actions().users.setUserRoles(currentUser.id, roles));
    }

    // if (userUpdate.currentModules ? userUpdate.currentModules.length === 0 : true) {
    //   userUpdate.currentModule = '';
    // }

    await userUpdateMutation.mutateAsync({
      id: currentUser.id,
      ...removeUndefined(userUpdate),
      ...(password !== '' && { password }),
    });

    if (user.id === currentUser.id && password && password !== '') {
      dispatch(Actions().auth.logout());
    }

    onClose();
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('enter-a-valid-email'))
      .required(t('something-is-required', { something: t('email') })),
    displayName: yup.string().required(t('something-is-required', { something: t('name') })),
    phoneNumber: yup.string(),
    address: yup.string(),
    abteilung: yup.string(),
  });

  const disableUser = async () => {
    userUpdateMutation.mutate({ id: currentUser.id, disabled: true });
  };

  const enableUser = async () => {
    userUpdateMutation.mutate({ id: currentUser.id, disabled: false });
  };

  const adminUser = useWithRights('superadmin', 'user_create', 'user_delete', 'user_edit') || user.id == currentUser?.id;

  async function reset2FA() {
    try {
      await API.auth.clear2Factor(currentUser?.id);
      notification({ type: 'success', title: t('two-factor-auth-reset-successfull') });
      dispatch(Actions().auth.logout());
    } catch (error) {
      notification({ type: 'error', title: t('two-factor-auth-reset-failed'), text: JSON.stringify(error) });
    }
  }

  const initialValues = useMemo(() => {
    const defaultValues = {
      displayName: '',
      phoneNumber: '',
      mobile: '',
      address: '',
      email: '',
      abteilung: '',
      rights: {},
      roles: {},
      currentModules: [],
      currentModule: '',
      language: 'en',
      notificationChannels: [],
    } as Core.User;
    if (currentUser) return { ...defaultValues, ...currentUser };
    return defaultValues;
  }, [currentUser]);
  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={1000}>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (currentUser) {
              await updateUser(values as any);
            } else {
              const { newPassword, ...rest } = values as any;
              await userCreateMutation.mutateAsync({ ...values, password: newPassword } as any);
            }
            onClose();
          } catch {}
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {(formik) => (
          <Card>
            <CardContent>
              {adminUser && <UserEditAdminContent formik={formik} inCreation={currentUser === undefined} />}
              {currentUser && !adminUser && <UserEditUserContent currentUser={currentUser} />}
              <CardActions sx={{ justifyContent: 'flex-end', mt: 4, padding: 0 }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('cancel')}
                </Button>
                <ConditionalRender render={Boolean(currentUser)}>
                  {!userEditsHimself && <ConditionalRenderWithRights user_edit>
                    {currentUser?.disabled ? (
                      <LoadingButton variant="outlined" color="success" loading={userUpdateMutation.isLoading}
                                     onClick={enableUser} startIcon={<FontAwesomeIcon icon={faBan}/>}>
                        {t('activate-user')}
                      </LoadingButton>
                    ) : (
                      <LoadingButton variant="outlined" color="error" loading={userUpdateMutation.isLoading}
                                     onClick={disableUser} startIcon={<FontAwesomeIcon icon={faBan}/>}>
                        {t('deactivate-user')}
                      </LoadingButton>
                    )}
                  </ConditionalRenderWithRights>}
                  <ConditionalRenderWithRights user_delete>
                    <ConditionalRenderIfOwn enableOwnAccountWithUid={currentUser?.id} invert>
                      <LoadingButton variant="contained" loading={userDeleteMutation.isLoading} color="error" onClick={() => setOpenDeletePopup(true)} startIcon={<FontAwesomeIcon icon={faTrash} />}>
                        {t('delete-user')}
                      </LoadingButton>
                    </ConditionalRenderIfOwn>
                  </ConditionalRenderWithRights>
                  {(currentUser?.isTwoFAEnforced || currentUser?.is2FAEnrolled) && (
                    <ConditionalRenderWithRights enableOwnAccountWithUid={currentUser?.id}>
                      <LoadingPromiseButton
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          notification({
                            type: 'info',
                            title: t('are-you-sure-to-reset-your-second-factor'),
                            blocking: true,
                            text: t('you-will-be-logged-out-and-need-to-add-a-new-secod-factor-at-the-next-login'),
                            actions: [
                              { type: 'primary', text: t('cancel'), action: () => null },
                              { type: 'error', text: t('reset'), action: async () => reset2FA() },
                            ],
                          })
                        }
                        disabled={formik.isSubmitting}
                        loading={formik.isSubmitting}
                        startIcon={<FontAwesomeIcon icon={faUndo} />}
                      >
                        {t('reset-2fa')}
                      </LoadingPromiseButton>
                    </ConditionalRenderWithRights>
                  )}
                  <ConditionalRenderWithRights user_edit enableOwnAccountWithUid={currentUser?.id}>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      onClick={formik.submitForm}
                      disabled={formik.isSubmitting}
                      loading={formik.isSubmitting}
                      startIcon={<FontAwesomeIcon icon={faSave} />}
                    >
                      {t('save')}
                    </LoadingButton>
                  </ConditionalRenderWithRights>
                </ConditionalRender>
                <ConditionalRender render={!currentUser}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={formik.submitForm}
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                  >
                    {t('create')}
                  </LoadingButton>
                </ConditionalRender>
              </CardActions>
            </CardContent>
            <MainDialog
              open={openDeletePopup}
              onCloseClick={() => setOpenDeletePopup(false)}
              onSaveClick={async () => {
                await deleteUser();
                setOpenDeletePopup(false);
              }}
              saveButtonColor="error"
              secondButtonColor="primary"
              modalTitle={t('confirm-delete')}
              description={t('confirm-delete-user-text')}
              buttonText={t('delete')}
            />
          </Card>
        )}
      </Formik>
    </StandardPopup>
  );
};
