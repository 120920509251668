import { ApplicationForm } from '@base/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faClock, faCopy, faEdit, faEllipsisV, faFileExport, faLock, faLockOpen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { StandardPopup } from '../../../components/common';
import { MainDialog } from '../../../components/common/popups/MainDialog';
import { CampaignFolderSelectPopup } from '../../fileManager/FolderSelectPopup';
import { useAutoUpdateState } from '../../fileManager/useAutoUpdateState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButtonUnpublished: {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.error.lighter,
      '&:hover': { backgroundColor: theme.palette.success.main, color: 'white' },
      width: 40,
      height: 40,
      marginLeft: theme.spacing(1),
      '& $iconNormal': { opacity: 1 },
      '&:hover $iconNormal': { opacity: 0 },
      '& $iconHover': { opacity: 0 },
      '&:hover $iconHover': { opacity: 1 },
    },
    iconButtonPublished: {
      color: theme.palette.success.main,
      backgroundColor: theme.palette.success.lighter,
      '&:hover': { backgroundColor: theme.palette.error.main, color: 'white' },
      width: 40,
      height: 40,
      marginLeft: theme.spacing(1),
      '& $iconNormal': { opacity: 1 },
      '&:hover $iconNormal': { opacity: 0 },
      '& $iconHover': { opacity: 0 },
      '&:hover $iconHover': { opacity: 1 },
    },
    iconNormal: {
      transition: 'all 200ms ease',
    },
    iconHover: {
      transition: 'all 200ms ease',
      position: 'absolute',
    },
  }),
);

export function SortableTableRow({
  r,
  onChange,
  onChangePublish,
  onDelete,
  onDuplicate,
  campaignId,
}: {
  onChangePublish: (published: boolean) => Promise<any>;
  r: ApplicationForm;
  onChange: (form: ApplicationForm) => void;
  onDelete: () => void;
  onDuplicate: (form: ApplicationForm, destinationCampaign?: string) => void;
  campaignId: string;
}): JSX.Element {
  const { attributes, listeners, setNodeRef, transform, transition, isSorting } = useSortable({ id: r.id });
  const history = useHistory();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [changeDeadlineOpen, setChangeDeadlineOpen] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const menuAnchorRef = useRef(null);

  const classes = useStyles(theme);
  const [menuOpen, setMenuOpen] = useState(false);

  const [selectOpen, setSelectOpen] = useState(false);

  const actions = [
    {
      title: t('duplicateReport'),
      icon: faCopy,
      action: () => onDuplicate(r),
    },
    {
      title: t('copyAndMoveReport'),
      icon: faFileExport,
      action: () => setSelectOpen(true),
    },
    {
      title: t('setDeadline'),
      icon: faClock,
      action: () => setChangeDeadlineOpen(true),
    },
    {
      title: t('deleteReport'),
      icon: faTrash,
      action: () => setDeletePopup(true),
    },
  ];

  return (
    <>
      <TableRow style={style} onDoubleClick={(e) => history.push(r.id + '/edit')}>
        <TableCell
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          style={{
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faGripVertical} />
        </TableCell>
        <TableCell>{r.name}</TableCell>
        <TableCell>{r.published ? 'Published' : 'Private'}</TableCell>
        <TableCell>
          <Deadline deadline={r.deadline} onClick={() => setChangeDeadlineOpen(true)} />
        </TableCell>
        <TableCell>
          <Button
            sx={{ borderRadius: 999, color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.main', color: 'white' } }}
            endIcon={<FontAwesomeIcon fixedWidth icon={faEdit} />}
            variant="contained"
            onClick={(e) => history.push(r.id + '/edit')}
          >
            {t('editReport')}
          </Button>
          <Tooltip title={r.published ? 'Unpublish' : 'Publish'}>
            <IconButton className={r.published ? classes.iconButtonPublished : classes.iconButtonUnpublished} onClick={() => onChangePublish(!r.published)}>
              <FontAwesomeIcon fixedWidth fontSize={20} width={20} height={20} size="sm" icon={r.published ? faLockOpen : faLock} className={classes.iconNormal} />
              <FontAwesomeIcon fixedWidth fontSize={20} width={20} height={20} size="sm" icon={!r.published ? faLockOpen : faLock} className={classes.iconHover} />
            </IconButton>
          </Tooltip>
          <IconButton
            sx={{ color: 'info.main', backgroundColor: 'info.lighter', ':hover': { bgcolor: 'info.main', color: 'white' }, width: 40, height: 40, ml: 1 }}
            ref={menuAnchorRef}
            onClick={() => setMenuOpen(true)}
          >
            <FontAwesomeIcon fixedWidth fontSize={20} width={20} height={20} size="sm" icon={faEllipsisV} />
          </IconButton>
          <Menu
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            anchorEl={menuAnchorRef.current}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {actions.map((a, i) => {
              return (
                <MenuItem
                  onClick={() => {
                    a.action();
                    setMenuOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon fixedWidth icon={a.icon} color="#006e9b" />
                  </ListItemIcon>
                  {a.title}
                </MenuItem>
              );
            })}
          </Menu>
        </TableCell>
        <MainDialog
          open={deletePopup}
          onCloseClick={() => setDeletePopup(false)}
          onSaveClick={async () => {
            onDelete();
          }}
          modalTitle={t('delete-form-title')}
          description={t('sure-to-delete-form')}
          buttonText={t('delete')}
        />
        <CampaignFolderSelectPopup
          onFolderSelected={async (id, campaign) => {
            await onDuplicate({ ...r, parent: id }, campaign);
            setSelectOpen(false);
          }}
          setVisible={setSelectOpen}
          visible={selectOpen}
          initialParent={campaignId}
          mimeTypes={['form']}
          showYearPicker={true}
        />
      </TableRow>
      <DeadlinePopup form={r} open={changeDeadlineOpen} onClose={() => setChangeDeadlineOpen(false)} onChange={onChange} />
    </>
  );
}

function Deadline({ deadline, onClick }: { deadline: ApplicationForm['deadline']; onClick: () => void }) {
  const { t } = useTranslation();

  if (typeof deadline === 'number') {
    return <Chip onClick={onClick} label={format(Number(deadline), t('full-date-mask'))} />;
  }
  if (deadline instanceof Object) {
    if (deadline.type === 'absolute') {
      return <Chip onClick={onClick} label={format(Number(deadline.value), t('full-date-mask'))} />;
    }
    return <Chip onClick={onClick} label={deadline.value + ' days after project completion'} />;
  }
  return <Chip onClick={onClick} label="Select deadline" />;
}

export function DeadlinePopup({ open, onClose, form, onChange }: { open: boolean; onClose: () => void; form: ApplicationForm; onChange: (form: ApplicationForm) => void }) {
  const [absDeadline, setAbsDeadline] = useAutoUpdateState((form.deadline as any)?.type === 'absolute', [form]);
  const [daysAfterCompletition, setDaysAfterCompletition] = useAutoUpdateState(((form.deadline as any)?.type === 'relative' && (form.deadline as any)?.value) || '30', [form]);
  const [date, setDate] = useAutoUpdateState(((form.deadline as any)?.type === 'absolute' && (form.deadline as any)?.value) || Date.now(), [form]);
  const { t } = useTranslation();

  const handleSave = () => {
    onChange({
      deadline: {
        type: absDeadline ? 'absolute' : 'relative',
        value: absDeadline ? date : daysAfterCompletition,
      },
    } as any);
    onClose();
  };

  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={500}>
      <Paper variant="outlined">
        <Grid container spacing={2} paddingX={2} paddingY={1}>
          <Grid display="flex" alignItems="center" flexDirection="row" item xs={10}>
            <Typography variant="button">Absolute deadline</Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch name="budgetRequired" checked={absDeadline} onChange={(e) => setAbsDeadline(e.target.checked)} />
          </Grid>
          <Grid item xs={12}>
            {absDeadline ? (
              <DatePicker
                allowKeyboardControl
                allowSameDateSelection
                mask={t('full-date-mask')}
                label="Choose Date"
                renderInput={(params) => <TextField margin="normal" {...params} variant="outlined" />}
                onChange={(d) => setDate(d.valueOf())}
                value={date ?? null}
              />
            ) : (
              <TextField
                variant="outlined"
                label="Days"
                value={daysAfterCompletition}
                onChange={(e) => setDaysAfterCompletition(e.target.value)}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">days after project completion</InputAdornment>,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button variant="outlined" color="error" onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button variant="contained" onClick={handleSave}>
                {t('save')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </StandardPopup>
  );
}
