import { API, ApplicationForm, FormSubmission, Group } from '@base/core';
import { FileProvider, FormViewerMinimalCompare, getBudgetFromDocument } from '@editors/form-editor';
import { faCheck, faEllipsisV, faEuroSign, faPlusCircle, faTimes, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Menu, MenuItem, Stack, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import React, { useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { combineLatest } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { map, switchMap } from 'rxjs/operators';
import { ContentHeader, usePopup } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { ContentView, NavbarSmall } from '../../components/layouts';
import { ContentContainer } from '../../components/layouts/ContentContainer';
import { useURLQuery } from '../../lib/hooks/useQuery';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { useUpdateFormContentMutation } from '../externalUsers/hooks/mutations';
import { Budget } from './Budget';
import { sendAcceptedNotification } from './sendAcceptedNotification';
import { useGetForms } from "./hooks";

function unique(value: string[]) {
  const s = value.reduce((map, v) => ({ ...map, [v]: true }), {});
  return Object.keys(s);
}
export interface SubmissionCompareViewProps {
  document: ApplicationForm;
  submissions: { submission: FormSubmission; group: Group }[];
  uploadFileProvider: FileProvider;
}
export default function SubmissionCompareView({ document, submissions, uploadFileProvider }: SubmissionCompareViewProps) {
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const user = useSelector((state: Core.StateType) => state.auth.user);
  const notify = usePopup();

  const history = useHistory();
  const query = useURLQuery();
  const ids = query.getAll('id');
  // console.log(history.location.pathname, history.location.search);
  const selected = unique(ids)
    .map((i) => submissions.find((s) => s.submission.id == i))
    .filter((i) => i);

  const updateSubmissionMutation = useUpdateFormContentMutation();

  const acceptRejectAction = async (action: 'accept' | 'reject', { campaignId, entityId, formId }: FormSubmission, index: number, newBudget?: string) => {
    const update = {
      campaignId,
      data: {
        state: action === 'accept' ? 'success' : 'rejected',
      },
      formId,
      groupId: entityId,
    } as any;
    if (newBudget) update.data.budgetOverride = newBudget;
    await updateSubmissionMutation.mutateAsync(update);
    if (action === 'accept' || action === 'reject') {
      await sendAcceptedNotification(entityId, action, newBudget, campaignId, formId, document.name);
    }
    //removeid
    history.replace(
      '?' +
        unique(ids.filter((_, idx) => idx != index))
          .map((i) => 'id=' + i)
          .join('&'),
    );
  };

  const mutation = useUpdateFormContentMutation();

  const renderToEmailText = (message) => {
    let retVal = '';

    message.content.forEach((c) => {
      if (c.type === 'paragraph') {
        c.content?.forEach((t) => {
          let bold = false;
          let cursive = false;
          let textColor;
          let word = t.text;

          if (t.marks) {
            t.marks.forEach((m) => {
              if (m.type === 'strong') {
                bold = true;
              } else if (m.type === 'em') {
                cursive = true;
              } else if (m.type === 'textColor') {
                textColor = m.attrs.color;
              }
            });
          }

          if (bold) {
            word = '<strong>' + word + '</strong>';
          }
          if (cursive) {
            word = '<em>' + word + '</em>';
          }
          if (textColor) {
            word = '<span style="color:' + textColor + '" >' + word + '</span>';
          }

          retVal += word;
        });

        retVal += '<br>';
      }
    });
    return retVal;
  }

  const handleSendChat = async (message: string, questionId: string, federationId: string) => {
    const content = submissions.find((v) => v.submission.entityId === federationId).submission;
    const chatId = 'formsubmissions:' + content.id;
    const form = document;
    try {
      await API.chats.createChatItem(chatId, {
        creator: user.id,
        text: message,
        questionId,
      });
      await API.email.sendChatNotificationEmail(
        { groupIds: [content.entityId] },
        renderToEmailText(message),
        form.content.find((c) => c.id == questionId)?.title ?? '',
        `${location.origin}/campaigns-external/${content.campaignId}/forms/${form.id}`,
        form.name,
      );
    } catch (error) {
      notify({
        title: t('error'),
        type: 'error',
        text: error.message || error,
      });
    }
  };
  const handleReadChatItems = (chatItems: string[], federationId: string) => {
    const content = submissions.find((v) => v.submission.entityId === federationId).submission;
    const chatId = 'formsubmissions:' + content.id;
    void API.chats.readChatItems(chatId, chatItems, user.id);
  };

  const chats = useObservable(
    ($state, $inputs) =>
      $inputs.pipe(
        map(([items]) => items),
        switchMap((items) => {
          const observables = items.map((s) => {
            const chatId = 'formsubmissions:' + s.submission.id;
            return API.chats.getChatObservable(chatId).pipe(map((chatItems) => [s.submission.entityId, chatItems]));
          });
          return combineLatest(observables);
        }),
        map((chats) => {
          return Object.fromEntries(chats);
        }),
      ),
    {},
    [submissions],
  );

  // not happy with always referencing the first element
  // works, because every submission which can be compared is always in tnside the same campaign
  const campaignIds = submissions.length > 0 ? submissions[0].submission.campaignId : '';
  const { data: forms } = useGetForms(campaignIds);

  const contextList = useMemo(() => {
    const elements = []

    submissions.forEach(item => {

      if (!forms) return;

      const submission = item.submission;
      const group = item.group;
      const form = forms.find((form) => {
        return form.id === submission.formId;
      });

      elements.push({
        group,
        form,
      })
    })

    return elements;
  }, [submissions, forms]);

  return (
    <ContentContainer sx={{ maxHeight: '100vh' }}>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.campaignOverview]}></NavbarSmall>
      <ContentView
        noPadding
      >
        <ContentHeader title="Compare submissions" subtitle="Choose groups to compare">
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1} />
            <Button ref={anchorRef} variant="contained" endIcon={<FontAwesomeIcon icon={faPlusCircle} />} onClick={() => setAddMenuOpen(true)}>
              {t('add-federation')}
            </Button>
          </Stack>
          <Menu anchorEl={anchorRef.current} keepMounted open={addMenuOpen} onClose={() => setAddMenuOpen(false)}>
            {submissions.map((v) => (
              <MenuItem
                key={v.group?.id}
                onClick={(e) => {
                  query.append('id', v.submission.id);
                  history.replace(
                    '?' +
                      unique(query.getAll('id'))
                        .map((i) => 'id=' + i)
                        .join('&'),
                  );
                  setAddMenuOpen(false);
                }}
              >
                {v.group?.name}
              </MenuItem>
            ))}
          </Menu>
        </ContentHeader>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            overflow: 'auto',
          }}
        >
          <FormViewerMinimalCompare
            document={document}
            renderHeader={(v, i) => {
              return (
                <Header
                  v={v}
                  action={(a, budget) => acceptRejectAction(a, v, i, budget)}
                  form={document}
                  i={i}
                  onRemove={() => {
                    history.replace(
                      '?' +
                        unique(ids.filter((_, idx) => idx != i))
                          .map((i) => 'id=' + i)
                          .join('&'),
                    );
                  }}
                />
              );
            }}
            uploadFileProvider={uploadFileProvider}
            renderFooter={() => <></>}
            values={selected.map((s) => ({
              ...s.submission,
              entityName: s.group?.name,
              data: { ...s.submission.data, budget: getBudgetFromDocument(document, s.submission.data) },
            }))}
            action={(questionId, action, federationId) => {
              const content = submissions.find((v) => v.submission.entityId === federationId).submission;

              mutation.mutate({
                campaignId: content.campaignId,
                formId: content.formId,
                groupId: content.entityId,
                data: {
                  [`review.${questionId}`]: { type: action },
                },
              });
            }}
            chats={chats}
            onSendChat={handleSendChat}
            readChatItems={handleReadChatItems}
            contextList={contextList}
            readOnly={true}
          />
        </Box>
      </ContentView>
    </ContentContainer>
  );
}

function Header({
  v,
  i,
  onRemove,
  form,
  action,
}: {
  v: FormSubmission & {
    entityName: string;
  };
  i: number;
  form: ApplicationForm;
  onRemove: () => void;
  action: (action: 'accept' | 'reject', newBudget?: string) => Promise<void>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOptionsCloseClick = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  const theme = useTheme();
  const [openAcceptSubmissionPopup, setOpenAcceptSubmissionPopup] = useState(false);
  const [openDismissSubmissionPopup, setOpenDismissSubmissionPopup] = useState(false);
  const [openChangeBudgetPopup, setOpenChangeBudgetPopup] = useState(false);

  const budgetText = form.budgetField ? ` with a budget of ${v.data.budget} €` : '';
  const [budget, setBudget] = useState(v.budgetOverride || v.data.budget);
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <MainDialog
        modalTitle={t('accept_submission')}
        description={`Are you sure you want to accept ${v.entityName}` + budgetText + '?'}
        open={openAcceptSubmissionPopup}
        onCloseClick={() => setOpenAcceptSubmissionPopup(false)}
        onSaveClick={() => action('accept')}
      />
      <MainDialog
        modalTitle={t('dismiss_submission')}
        description={`Are you sure you want to dismiss ${v.entityName}` + budgetText + '?'}
        open={openDismissSubmissionPopup}
        onCloseClick={() => setOpenDismissSubmissionPopup(false)}
        onSaveClick={() => action('reject')}
      />
      <MainDialog
        modalTitle={t('change_budget')}
        description={`The requested Budget from ${v.entityName} is ${v.data.budget} €`}
        open={openChangeBudgetPopup}
        onCloseClick={() => setOpenChangeBudgetPopup(false)}
        buttonText="Accept with new Budget"
        onSaveClick={async () => {
          await action('accept', budget);
        }}
      >
        <TextField label={t('corrected_budget')} sx={{ marginTop: 2 }} fullWidth variant="outlined" value={budget} type="number" onChange={(e) => setBudget(parseFloat(e.target.value))} />
      </MainDialog>
      <Box>
        {v.entityName}
        {form.budgetField && <Budget budget={v.data.budget} budgetOverride={v.budgetOverride} />}
      </Box>
      <Box display="flex">
        <Tooltip title="Remove from this list">
          <IconButton
            sx={{
              color: 'error.main',
              backgroundColor: 'error.lighter',
              ':hover': { bgcolor: 'error.main', color: 'white' },
              width: 34,
              height: 34,
            }}
            onClick={onRemove}
          >
            <FontAwesomeIcon style={{ width: 18, height: 18 }} icon={faTrash} />
          </IconButton>
        </Tooltip>
        <IconButton
          sx={{
            color: 'info.main',
            backgroundColor: 'info.lighter',
            ':hover': { bgcolor: 'info.main', color: 'white' },
            width: 34,
            height: 34,
            marginLeft: 1,
          }}
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOptionsClick}
        >
          <FontAwesomeIcon style={{ width: 18, height: 18 }} icon={faEllipsisV} />
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleOptionsCloseClick}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              setOpenAcceptSubmissionPopup(true);
              handleOptionsCloseClick();
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faCheck} color={theme.palette.success.main} />
            <Typography sx={{ marginLeft: 1 }}>{t('accept_submission')}</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenDismissSubmissionPopup(true);
              handleOptionsCloseClick();
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faTimes} color={theme.palette.error.main} />
            <Typography sx={{ marginLeft: 1 }}>{t('dismiss_submission')}</Typography>
          </MenuItem>
          {form.budgetField && (
            <MenuItem
              onClick={() => {
                setOpenChangeBudgetPopup(true);
                handleOptionsCloseClick();
              }}
            >
              <FontAwesomeIcon fixedWidth icon={faEuroSign} color={theme.palette.info.main} />
              <Typography sx={{ marginLeft: 1 }}>{t('change_budget')}</Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  );
}
