import { EmailTemplates } from '@base/firebase';
import { AccessRequestDoc, AccessRequestFileProps, AccessRequestFormData, IAccessRequestService, IEmailService, IGroupsService } from '../entities';
import { UsersInteractor } from './UsersInteractor';
import { includes } from 'lodash'

export class AccessRequestInteractor {
  private emailTemplateService;

  constructor(private accessRequestService: IAccessRequestService, private userInteractor: UsersInteractor, private groupService: IGroupsService, private emailService: IEmailService) {
    this.emailTemplateService = new EmailTemplates();
  }

  /**
   * uploads files to firebase storage and creates a firestore document with references to the file and the form data
   * @param accessFormData The data from the access form.
   */
  async sendAccessRequest(accessFormData: AccessRequestFormData) {
    await this.accessRequestService.sendAccessRequest(accessFormData);
  }

  /**
   * Loads all access request documents from the firebase
   * @returns Promise with an Array of Access request documents
   */
  async getAllPendingAccessRequests(): Promise<AccessRequestDoc[]> {
    return await this.accessRequestService.getAllPendingAccessRequests();
  }

  /**
   * Loads all rejected Access Requests from the firebase
   * @returns Promise with an Array of rejected Access request documents
   */
  async getAllRejectedAccessRequests(): Promise<AccessRequestDoc[]> {
    return await this.accessRequestService.getAllRejectedAccessRequests();
  }

  /**
   * This method creates a new 'group only admin' user and a new group
   *
   * The new user gets assigned to the new group and the group will receive alle data from the access request
   *
   * The access document from firestore gets deleted
   * @param request the request to verify
   * @param subject The subject of the email to the new user
   */
  async allowAccessRequest(request: AccessRequestDoc, subject?: string) {
    try {
      const newGroupPromise = this.groupService.createGroup({ requestData: request, name: request.organizationName, description: request.country, type: request.type ?? 'federation' });
      const newGroup = await newGroupPromise;
      if (!includes(request.email, '@birnensoft.at')) {
        const newUser = await this.userInteractor.createUser({
          disabled: false,
          displayName: request.displayName,
          jobTitle: request.position,
          email: request.email,
          emailVerified: false,
          phoneNumber: request.phoneNumber,
          passwordChangeRequired: true,
        });
        await this.userInteractor.setUserRights(newUser.id, {
          is_group_only_user: true,
        });
        await this.userInteractor.setUserRights(newUser.id, {
          is_group_only_user: true,
          groupRights: {
            group_admin: [newGroup.id],
            user_create: [newGroup.id],
            user_edit: [newGroup.id],
            user_delete: [newGroup.id],
            user_right_edit: [newGroup.id],
            can_submit_applications: [newGroup.id],
          },
        });
        await this.userInteractor.updateUserGroups(newUser.id, { add: [newGroup.id], remove: [] });
      }
    } catch (error) {
      console.log(error);
      throw new Error('Could not create new user from access request. Error: ' + JSON.stringify(error));
    }
    try {
      await this.accessRequestService.deleteAccessRequestWithoutFile(request);
    } catch (error) {
      throw new Error('Could not delete Access Request: ' + JSON.stringify(error));
    }
    //notify people
    await this.emailService.sendNotification({ emails: request.email }, subject ? subject : 'Access request accepted! - IBU Scope', {
      emailText: this.emailTemplateService.getAccessRequestVerifiedContent(request.displayName),
      autosend: true,
    });
  }

  /**
   * The access request will be saved as rejected with the reject message
   *
   * A notification is send to the denied user
   * @param request
   * @param message message for the email to the denied replicant
   */
  async denyAccessRequest(request: AccessRequestDoc, message?: string) {
    //set Request rejected and add the reject message
    await this.accessRequestService.updateAccessRequest({ ...request, rejected: true, rejectMessage: message });
    //notify people
    await this.emailService.sendNotification(
      { emails: request.email },
      this.emailTemplateService.ibuAccessRequestDeniedSubject,
      {
        emailTitle: 'Your access request has been denied!',
        emailText: this.emailTemplateService.getAccessRequestDeniedContent(request.displayName, message),
        autosend: true,
      },
      'notification_template_basic'
    );
  }

  /**
   * Deletes the old file, uploads the new file and updates the access request doc with the new file
   * @param accessRequestDoc the access request doc to apply the changes to
   * @param oldFilePath the firebase.storage.Reference to the old file to delete it
   * @param file the new file as Blob object
   */
  async swapAccessRequestFile(accessRequestDoc: AccessRequestDoc, oldFilePath: string, file: File): Promise<AccessRequestDoc> {
    const newFile = await this.accessRequestService.uploadAccessRequestFile(file);
    accessRequestDoc.files = accessRequestDoc.files.filter((file) => file.firebasePath != oldFilePath);
    accessRequestDoc.files = [...(accessRequestDoc.files ?? []), newFile];
    await this.accessRequestService.deleteAccessRequestFile(oldFilePath);
    return await this.accessRequestService.updateAccessRequest(accessRequestDoc);
  }

  /**
   * Uploads the new file and updates the access request document
   * @param accessRequestDoc the access request doc to apply the changes to
   * @param file the new file as Blob object
   */
  async addAccessRequestFile(accessRequestDoc: AccessRequestDoc, file: File): Promise<AccessRequestDoc> {
    const newFile = await this.accessRequestService.uploadAccessRequestFile(file);
    accessRequestDoc.files = [...(accessRequestDoc.files ?? []), newFile];
    return await this.accessRequestService.updateAccessRequest(accessRequestDoc);
  }

  /**
   * Uploads the new file
   * @param file the new file as Blob object
   */
  async uploadAccessRequestFile(file: File): Promise<AccessRequestFileProps> {
    return await this.accessRequestService.uploadAccessRequestFile(file);
  }

  /**
   * deletes the given file
   * @param file the new file as AccessRequestFileProps
   */
  async deleteAccessRequestFile(file: AccessRequestFileProps): Promise<void> {
    return await this.accessRequestService.deleteAccessRequestFile(file.firebasePath);
  }
}
