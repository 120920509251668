import { Campaign, getKeysWithTruthyValues, Group } from '@base/core';
import { faCommentLines, faCopy, faEdit, faFileExport, faTrash, faLayerPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Chip, Stack, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, XGrid } from '@material-ui/x-grid';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import {ContentHeader, MainMenu, usePopup} from '../../components/common';
import { SearchInput } from '../../components/inputs';
import { ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import { CombinedConditionalRender } from '../../helpers/ConditionalRenderWithRights';
import { getNotificationCountFromCampaign } from '../../lib/notificationCountHelpers';
import { BREADCRUMBS } from '../../router/BreadcrumbsType';
import { CampaignFolderSelectPopup } from '../fileManager/FolderSelectPopup';
import { useDebouncedState } from '../users';
import { CampaignCreationPopup } from './CampaignCreationPopup';
import { getCurrentBiathlonYear } from './getCurrentBiathlonYear';
import { useGetFormSubmissions } from './hooks/useGetFormSubmissions';
import { BiathlonYearPicker } from './settings/BiathlonYearPicker';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';
import { BiathlonYearPickerPopup } from "./popups/BiathlonYearPickerPopup";

export interface CampaignManagerProps {
  campaigns: Campaign[];
  parents: Core.VirtualFile[];
  groups: Group[];
  baseUrl: string;
  onCreateCampaign(name: string, description: string, biathlonYear: string): void | Promise<void>;
  onDeleteCampaign(campaign: Campaign): void | Promise<void>;
  onMoveCampaign(campaign: Campaign, newParent: string): void | Promise<void>;
  onDuplicateCampaign(campaign: Campaign, newParent?: string): void | Promise<void>;
  onDuplicateCampaignToYear(campaign: Campaign, biathlonYear: string, newParent?: string): void | Promise<void>;
  loading?: boolean;
  onCampaignDoubleClick(campaign: Campaign): void;
}

const useStyles = makeStyles({
  root: (theme: any) => ({
    flex: 1,
    height: 'auto',
    '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
    '& .MuiDataGrid-row': {
      position: 'relative',
      minWidth: '100%',
    },
    '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
  }),
  lastCell: {
    minWidth: '100px !important',
    position: 'absolute',
    left: 0,
    zIndex: 10,
    transform: 'translateX(calc(100vw - 100% - 24px))',
    justifyContent: 'flex-end',
  },
});

export function CampaignManager({
  campaigns,
  onCreateCampaign,
  loading,
  onCampaignDoubleClick,
  parents = [],
  baseUrl,
  onMoveCampaign,
  onDuplicateCampaign,
  onDuplicateCampaignToYear,
  onDeleteCampaign,
  groups,
}: CampaignManagerProps) {
  const theme = useTheme();
  const groupsMap: { [key: string]: Group } = useMemo(() => groups.reduce((map, g) => ({ ...map, [g.id]: g }), {}), [groups]);
  const classes = useStyles(theme);
  const [campaignCreationOpen, setCampaignCreationOpen] = useState(false);
  const notify = usePopup();
  const { t } = useTranslation();
  const me = useSelector((state: Core.StateType) => state.auth.user);

  const columns: (lastCellClassName: string) => GridColDef[] = (lastCellClassName: string) => [
    {
      field: 'name',
      flex: 1,
      headerName: t('name'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography style={{ marginLeft: 8 }}>{value}</Typography>;
      },
    },
    {
      field: 'releaseDate' as keyof Campaign,
      width: 140,
      headerName: t('release-date'),
      type: 'date',
      disableColumnMenu: true,
      renderCell: ({ formattedValue, value }) => <Chip label={formattedValue ?? '-'} />,
    },
    {
      field: 'selectedGroups',
      headerName: t('entitled'),
      width: 120,
      renderCell: ({ value }) => (
        <Chip
          label={
            getKeysWithTruthyValues(value as any)
              .map((id) => groupsMap[id])
              .filter((d) => d).length ?? '-'
          }
        />
      ),
    },
    {
      field: 'biathlonYear',
      hide: true,
      headerName: 'Biathlon Season',
      width: 150,
    },
    {
      field: 'currentApplications',
      width: 75,
      filterable: false,
      editable: false,
      hideSortIcons: true,
      resizable: false,
      renderCell: ({ value, row }) => {
        const { data: formSubmissions } = useGetFormSubmissions(row.id);
        return (
          <Box
            sx={{
              minWidth: 16,
              backgroundColor: theme.palette.background.grayDark,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 50,
              padding: 0.4,
              paddingX: 1,
              color: 'white',
              flexShrink: 0,
              fontSize: 12,
              fontWeight: '600',
            }}
          >
            <Typography variant="subtitle2">
              <FontAwesomeIcon style={{ marginRight: 5 }} icon={faLayerPlus} />
              {formSubmissions?.filter((f) => f.state === 'submitted').length ?? '-'}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'unreadComments',
      width: 75,
      filterable: false,
      editable: false,
      hideSortIcons: true,
      resizable: false,
      renderCell: ({ value, row }) => {
        const unreadCount = useObservable<number, [Campaign]>(
          (state$, input$) =>
            input$.pipe(
              map(([Campaign]) => Campaign),
              getNotificationCountFromCampaign(me.id),
            ),
          0,
          [row as Campaign],
        );
        return (
          <Box
            sx={{
              minWidth: 16,
              backgroundColor: theme.palette.error.main,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 50,
              padding: 0.4,
              paddingX: 1,
              color: 'white',
              flexShrink: 0,
              fontSize: 12,
              fontWeight: '600',
            }}
          >
            <Typography variant="subtitle2">
              <FontAwesomeIcon style={{ marginRight: 5 }} icon={faCommentLines} />
              {unreadCount ?? '-'}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.5,
      align: 'right',
      cellClassName: lastCellClassName,
      renderCell: ({ value, row }) => {
        const [movePopupVisible, setMovePopupVisible] = useState(false);
        const [copyAndMovePopupVisible, setCopyAndMovePopupVisible] = useState(false);
        const [yearPickerOpen, setYearPickerOpen] = useState(false);

        const menuActions = [
          {
            title: t('open-campaign'),
            icon: faEdit,
            action: () => {
              onCampaignDoubleClick(row as Campaign);
            },
          },
          {
            title: t('copy') + ' ' + t('form') + ' to current Year',
            icon: faCopy,
            action: async () => {
              await onDuplicateCampaign(row as Campaign);
            },
          },
          {
            title: t('copy') + ' ' + t('form') + ' to selectable Year',
            icon: faCopy,
            action: async () => {
              setYearPickerOpen(true)
            },
          },
          {
            title: t('move') + ' ' + t('form'),
            icon: faFileExport,
            action: () => {
              setMovePopupVisible(true);
            },
          },
          {
            title: t('copy') + ' & ' + t('move') + ' ' + t('form'),
            icon: faFileExport,
            action: () => {
              setCopyAndMovePopupVisible(true);
            },
          },
          {
            title: t('delete-form'),
            icon: faTrash,
            action: async () => {
              await onDeleteCampaign(row as Campaign);
            },
          },
        ];
        return (
          <>
            <MainMenu
              id={row.id}
              menuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              }}
              actions={menuActions}
            />
            <BiathlonYearPickerPopup
              open={yearPickerOpen}
              onClose={() => setYearPickerOpen(false)}
              onSubmit={async (year) => {
                try {
                  await onDuplicateCampaignToYear(row as Campaign, year)
                  notify({
                    title: t('copied'),
                    type: 'success',
                  });
                } catch (error) {
                  notify({
                    title: t('error'),
                    type: 'error',
                    text: error.message,
                  });
                }
                setYearPickerOpen(false);
              }}
            />
            <CampaignFolderSelectPopup
              setVisible={setMovePopupVisible}
              visible={movePopupVisible}
              initialParent={null}
              onFolderSelected={(parent) => onMoveCampaign(row as Campaign, parent)}
              resetOnVisible
              mimeTypes={['folder']}
            />
            <CampaignFolderSelectPopup
              setVisible={setCopyAndMovePopupVisible}
              visible={copyAndMovePopupVisible}
              initialParent={null}
              onFolderSelected={(parent) => onDuplicateCampaign(row as Campaign, parent)}
              resetOnVisible
              mimeTypes={['folder']}
            />
          </>
        );
      },
      field: '1',
      filterable: false,
      disableColumnMenu: true,
    },
  ];
  const [filterModel, setFilterModel, fastFilterModel] = useDebouncedState<GridFilterModel>({
    items: [
      {
        columnField: 'biathlonYear',
        operatorValue: 'equals',
        value: getCurrentBiathlonYear(),
      },
    ],
  });

  return (
    <ContentContainer>
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.campaignOverview, ...parents.map((p) => ({ path: baseUrl + p.id, name: p.name }))]}></NavbarSmall>
      <ContentView noPadding>
        <ContentHeader title={parents[parents.length - 1]?.name} subtitle={t('campaigns-counted', { count: campaigns?.length })} href={BREADCRUMBS.campaignOverview.path}>
          <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
            <Box flex={1}>
              <SearchInput
                size="small"
                InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
                placeholder={t('search')}
                fullWidth
                value={fastFilterModel?.items?.find((i) => i.columnField == 'name')?.value ?? ''}
                onChange={(e) => {
                  try {
                    new RegExp(e.target.value);
                    setFilterModel({ items: [{ columnField: 'name', operatorValue: 'contains', value: e.target.value }] });
                  } catch {}
                }}
              />
            </Box>
            <BiathlonYearPicker
              sx={{ width: 150 }}
              value={fastFilterModel?.items?.find((i) => i.columnField === 'biathlonYear')?.value ?? 'all'}
              size="small"
              // size="small"
              clearEnabled
              onChange={(e) => {
                try {
                  setFilterModel({ items: [{ columnField: 'biathlonYear', operatorValue: 'equals', value: e.target.value !== 'all' ? (e.target.value as string) : null }] });
                } catch {}
              }}
            />
            <CombinedConditionalRender campaign_admin parent={parents[0]} write>
              <Button
                variant="contained"
                sx={{ color: 'primary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999 }}
                endIcon={<FontAwesomeIcon icon={faLayerPlus} />}
                onClick={() => setCampaignCreationOpen(true)}
              >
                {t('create')}
              </Button>
            </CombinedConditionalRender>
          </Stack>
        </ContentHeader>
        <XGrid
          className={classes.root}
          headerHeight={40}
          // disableColumnSelector
          // disableColumnMenu
          sortModel={[{ field: 'name', sort: 'asc' }]}
          onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
          filterModel={filterModel}
          columns={columns(classes.lastCell).map((c) => ({
            ...c,
            renderHeader: (params) => {
              return (
                <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                  {params.colDef.headerName}
                </Typography>
              );
            },
          }))}
          components={{
            NoRowsOverlay: GridNoRowsOverlay,
            NoResultsOverlay: GridNoResultsOverlay,
          }}
          rows={campaigns}
          loading={loading}
          onRowDoubleClick={(params) => {
            onCampaignDoubleClick(params.row as Campaign);
          }}
        />
        <CampaignCreationPopup onClose={() => setCampaignCreationOpen(false)} onCreate={onCreateCampaign} open={campaignCreationOpen} />
      </ContentView>
    </ContentContainer>
  );
}
