import { Grid, Stack, Typography } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConditionalRender } from '../../components/common';
import '../../css/Views/admin/users/UsersOverride.scss';
import { useTheme } from '../../theme/ThemeProvider';
import { useGetUsers } from '../users/hooks/useGetUsers';
import { BasicInformationEditControl } from '../users/useredit-admin-content/BasicInformationEditControl';
import { LanguageSelect } from '../users/useredit-admin-content/LanguageSelect';
import { UserGroupRightsSelect } from '../users/useredit-admin-content/UserGroupRightsSelect';
import { UserImageEditControl } from '../users/useredit-admin-content/UserImageEditControl';
import { containsRight } from './containsRight';

interface UserEditAdminContentProps {
  formik: FormikProps<
    Core.User & {
      newPassword?: string;
      currentModules?: string[];
      grouprights: Core.GroupRights;
    }
  >;
  inCreation?: boolean;
  rights: Core.GroupRights;
  groupId: string;
}

export function UserEditAdminContentExternal({ formik: { values, setValues, handleChange, setFieldValue, errors, touched }, inCreation, rights, groupId }: UserEditAdminContentProps) {
  console.log('🚀 ~ file: UserEditAdminContentExternal.tsx ~ line 31 ~ UserEditAdminContentExternal ~ values', values);
  const theme = useTheme();
  const uid = values.id;

  const { data: users = [] } = useGetUsers();
  const { t } = useTranslation();
  const { user: currentUser } = useSelector((state: Core.StateType) => state.auth);

  return (
    <Grid container spacing={2}>
      <UserImageEditControl
        currentUser={values}
        onImage={(image) => {
          setFieldValue('userImage', image);
          const reader = new FileReader();
          reader.onload = (e) => {
            setFieldValue('photoURL', e.target!.result as string);
          };
          reader.readAsDataURL(image);
        }}
        userName={values.displayName}
        userPhotoUrl={values.photoURL}
      />
      <Grid item xs={12} marginTop={2}>
        <Typography variant="overline">{t('basic-information')}</Typography>
      </Grid>
      <BasicInformationEditControl touched={touched} errors={errors} inCreation={inCreation} isOwn={values.id === currentUser.id} values={values} setFieldValue={setFieldValue} />
      <ConditionalRender render={containsRight(rights, groupId, 'user_edit') || (containsRight(rights, groupId, 'user_create') && inCreation) || values.id == currentUser.id}>
        <Grid item xs={12} md={6} mt={2}>
          <Stack spacing={2}>
            <Typography variant="overline">{t('language')}</Typography>
            <Field as={LanguageSelect} name="language" />
          </Stack>
        </Grid>
      </ConditionalRender>
      <ConditionalRender render={containsRight(rights, groupId, 'user_edit') || (containsRight(rights, groupId, 'user_create') && inCreation) || values.id == currentUser.id}>
        <Field as={UserGroupRightsSelect} name="grouprights" groupId={groupId} setFieldValue={setFieldValue} />
      </ConditionalRender>
    </Grid>
  );
}
