import { faStar as faStarLight } from '@fortawesome/free-solid-svg-icons';
import { faCopy, faDownload, faEdit, faEye, faFileSignature, faFolderTree, faLink, faStar as faStarRegular, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import '../../css/components/common/content/ContentElement.scss';
import { useGetRightsForFile, useWithRights } from '../../helpers/ConditionalRenderWithRights';
import { useCreateSIDocumentMutation, useDeleteFileMutation } from '../../views/fileManager/hooks';
import { AccessLinkPopup, ContextMenuElementType, RenamePopup } from '../common';
import AsyncImage from '../common/AsyncImage';
import { cssClasses, cx } from '../HelperFunctions';
import { cutExtensionfromName, downloadFile, getFileExtension, useFileIcon } from './ContentElementHelper';

type ContentElementProps = {
  selected: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDoubleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  contentCount?: number;
  timeStamp?: number;
  favorite?: boolean;
  setFavorite?: () => Promise<any>;
  fileSize?: number;
  file: Core.VirtualFile;
  fileRights?: any;
  setContextMenuItems: (fileItems: ContextMenuElementType[], position: { mouseX: number; mouseY: number }) => void;
  onFileMove?: () => void;
};

export const ContentElement = ({
  selected,
  onClick,
  onDoubleClick,
  file,
  fileRights = { write: false, read: false, move: false, changePermission: false },
  contentCount,
  timeStamp,
  setFavorite,
  favorite,
  fileSize = undefined,
  setContextMenuItems,
  onFileMove,
}: ContentElementProps) => {
  const [renamePopupOpen, setRenamePopupOpen] = useState(false);
  const [linkPopupActive, setLinkPopupActive] = useState(false);
  const [favoriteLoading, setFavoriteLoading] = useState(false);

  const theme = useTheme();
  const FileIcon = useFileIcon(file.type, file.id);
  const extension = getFileExtension(file.type);
  const { t } = useTranslation();
  const history = useHistory();
  const createSiDocMutation = useCreateSIDocumentMutation();
  const deleteFileMutation = useDeleteFileMutation();

  const currentFileRights = useGetRightsForFile(file);
  fileRights.write = fileRights.write || currentFileRights.write;
  fileRights.read = fileRights.read || currentFileRights.read;
  fileRights.move = fileRights.move || currentFileRights.move;
  fileRights.changePermission = fileRights.changePermission || currentFileRights.changePermissions;

  const deleteElement = (fileToDelte: Core.VirtualFile) => deleteFileMutation.mutate(fileToDelte);

  const toggleFavorite = async () => {
    setFavoriteLoading(true);
    try {
      await setFavorite();
    } catch (error) {
      console.error(error);
    } finally {
      setFavoriteLoading(false);
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick(event);
  };

  const handleContextMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    return {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    };
  };

  async function openPDFUsingBrowserPDFViewer(currentFile) {
    window.open(await (currentFile as Core.NormalFile).downloadUrl, '_blank');
  }

  function onElementContextMenuStart(currentFile: Core.VirtualFile): ContextMenuElementType[] {
    const openFolder = (id: string) => history.push(`/files/${id}`);

    const contextOptions: ContextMenuElementType[] = [];
    if (!currentFile) return [];
    if (currentFile.type === 'folder') {
      contextOptions.push({
        text: t('open'),
        icon: faEye,
        onClick: () => openFolder(currentFile.id),
      });
    } else if (currentFile.type === 'si/document') {
      contextOptions.push({
        text: t('open-with-reader'),
        icon: faEye,
        onClick: () => history.push(`/files/${currentFile.id}?read`),
      });
      if (fileRights.write) {
        contextOptions.push({
          text: t('open-with-editor'),
          icon: faEdit,
          onClick: () => history.push(`/files/${currentFile.id}?edit`),
        });
      }
    } else {
      contextOptions.push({
        text: t('open'),
        icon: faEye,
        onClick: async () => {
          currentFile.type === 'application/pdf' ?
            await openPDFUsingBrowserPDFViewer(currentFile) :
            downloadFile(currentFile as Core.NormalFile);
        },
      });
      contextOptions.push({
        text: t('download'),
        icon: faDownload,
        onClick: () => downloadFile(currentFile as Core.NormalFile),
      });
    }
    if (fileRights.write) {
      contextOptions.push(
        {
          text: t('rename'),
          icon: faFileSignature,
          onClick: () => {
            setRenamePopupOpen(true);
          },
        },
        {
          text: t('delete'),
          icon: faTrash,
          onClick: () => {
            deleteElement(currentFile);
          },
        },
      );
    }
    if (fileRights.write && currentFile.type === 'si/document') {
      contextOptions.push({
        text: t('create-copy'),
        icon: faCopy,
        onClick: async () => {
          currentFile.name = currentFile.name ? `${t('copy-of')}${currentFile.name}` : `${t('copy-of')}${t('unnamed')}`;
          await createSiDocMutation.mutateAsync({ ...currentFile });
        },
      });
    }
    if (fileRights.move) {
      onFileMove &&
        contextOptions.push({
          text: t('move-file'),
          icon: faFolderTree,
          onClick: () => onFileMove(),
        });
    }
    contextOptions.push({
      text: t('share-link'),
      icon: faLink,
      onClick: () => {
        setLinkPopupActive(!linkPopupActive);
      },
    });

    return contextOptions;
  }
  const isImage = file.type.startsWith('image/');
  const isExternal = useWithRights('is_group_only_user');

  return (
    <Box
      onContextMenu={(event) => {
        event.stopPropagation();
        setContextMenuItems(onElementContextMenuStart(file), handleContextMenuClick(event));
      }}
      className={cssClasses({ selected: selected, folder: file.type === 'folder', file: file.type !== 'folder' }, 'content-element no-user-select')}
      onClick={onClickHandler}
      onDoubleClick={onDoubleClick}
    >
      {isImage ? (
        <AsyncImage alt={'preview'} src={(file as Core.NormalFile)?.downloadUrl} style={{ height: 76, width: 110, objectFit: 'cover', borderRadius: 14, marginLeft: -10, marginRight: 10 }} />
      ) : (
        <FileIcon />
      )}
      <Typography variant="subtitle1" sx={{ wordBreak: 'break-word' }} lineHeight={1.3} fontWeight={500}>
        {cutExtensionfromName(file.name ?? t('unnamed'), extension)}
        {extension !== '.false' && extension}
      </Typography>
      {favoriteLoading ? (
        <CircularProgress size={16} className={'favorite-icon'} />
      ) : (
        !isExternal && <FontAwesomeIcon icon={favorite ? faStarLight : faStarRegular} onClick={toggleFavorite} className={cx('favorite-icon', favorite && 'is-favorite')} />
      )}
      <RenamePopup open={renamePopupOpen} fileId={file.id} fileName={file.name} onClose={() => setRenamePopupOpen(false)} />
      <AccessLinkPopup setActive={setLinkPopupActive} active={linkPopupActive} relativeLinkUrl={'/files/' + file.id + (file.type === 'si/document' ? '/read' : '')} />
    </Box>
  );
};
