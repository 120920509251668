import { getKeysWithTruthyValues, Group } from '@base/core';
import { faEdit, faLock, faMailBulk, faTrash, faUserShield } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, Button, Chip, Link, Stack, Typography, useTheme } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { GridColDef, GridFilterModel, GridLinkOperator, XGrid } from '@material-ui/x-grid';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { API } from '../..';
import { ConditionalRender, ContentHeader, MainMenu, useNotificationEffect, usePopup, UserImageHandler } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { SearchInput } from '../../components/inputs';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { ConditionalRenderWithRights } from '../../helpers/ConditionalRenderWithRights';
import { useDeleteUsersMutation, useUpdateGroupsMutation } from './hooks/mutations';
import { useGetGroups } from './hooks/useGetGroups';
import { useGetUsers } from './hooks/useGetUsers';
import { ResetPasswordPopup } from './popups';
import { UserEdit } from './UserEdit';
import { useDebouncedState } from './Users';
import { GridNoResultsOverlay, GridNoRowsOverlay } from '../../components/content/GridContent';
import {DisplayUser, SendEmailToUsersPopup, TSelectableUser} from '../../components/users';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      flex: 1,
      height: 'auto',
      '& .MuiDataGrid-columnsContainer': { background: theme.palette.background.grayLight },
      '& .MuiDataGrid-row': {
        position: 'relative',
        minWidth: '100%',
        cursor: 'pointer',
        '&:hover': {
          '& $lastCell': {
            opacity: 1,
          },
        },
      },
      '& .MuiDataGrid-colCell:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
    },
  }),
);
type SearchOptimizedUserType = Core.User & { groupNames: string[] };

export const ExternalUsers = () => {
  const { t } = useTranslation();
  const { data: userData = [], isLoading } = useGetUsers();
  const usersList = userData.filter((u) => u.rights?.is_group_only_user);
  const { data: groupsList = [] } = useGetGroups();
  const { user } = useSelector((state: Core.StateType) => state.auth);

  const [selection, setSelection] = useState([]);
  const [createUserPopupOpen, setCreateUserPopupOpen] = useState(null);
  const updateGroupsMutation = useUpdateGroupsMutation();

  const searchOptimizedUsers: SearchOptimizedUserType[] = useMemo(() => {
    const modifiedUsers = usersList.map((user) => {
      user['groupNames'] = getKeysWithTruthyValues(user.groups as any)
        .map((groupId) => groupsList.find((group) => group.id === groupId))
        .filter((g) => g)
        .map((group: Group) => group.name);
      return user as SearchOptimizedUserType;
    });
    return modifiedUsers ?? [];
  }, [groupsList, usersList]);

  const theme = useTheme();
  const classes = useStyles(theme);

  const columns: { [key: string]: Omit<GridColDef, 'field'> } = {
    photoURL: {
      width: 65,
      renderCell: ({ formattedValue, value, row }) => {
        return <UserImageHandler userName={(row as Core.User).displayName} userPhotoUrl={value as any} style={{ height: 42, width: 42, marginRight: 16, borderStyle: 'none' }} fontSize={16} />;
      },
    },
    displayName: {
      width: 250,
      headerName: t('name'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Typography variant="subtitle1">{value}</Typography>;
      },
    },
    groupNames: {
      width: 180,
      headerName: 'Member of',
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <>
            {(value as string[]).map((groupName: string) => (
              <Chip label={groupName || '-'} key={groupName} />
            ))}
          </>
        );
      },
    },
    jobTitle: {
      width: 220,
      headerName: t('position'),
      renderCell: ({ formattedValue, value, row }) => {
        return <Chip label={value || '-'} />;
      },
    },
    email: {
      width: 350,
      headerName: 'E-Mail',
      renderCell: ({ formattedValue, value, row }) => {
        return (
          <Link variant="subtitle1" href={'mailto:' + value} onClick={(e) => e.stopPropagation()}>
            {value}
          </Link>
        );
      },
    },
    phoneNumber: { headerName: t('phone-number'), flex: 2, hide: true },
    mobile: { headerName: t('mobile-number'), flex: 2, hide: true },
    address: { headerName: t('address'), flex: 2, hide: true },
    language: { headerName: t('language'), flex: 1, hide: true },
    birthDate: { headerName: t('birthdate'), type: 'date', flex: 1, hide: true },
    notes: { headerName: t('notes'), flex: 2, hide: true },
    emailVerified: { headerName: t('email-verified'), hide: true },
    disabled: { headerName: t('user-disabled'), hide: true },
    lastCell: {
      flex: 1,
      align: 'right',
      renderCell: ({ value, row }) => {
        const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
        const [editUserPopupOpen, setEditUserPopupOpen] = useState(false);

        const menuActions = [];

        if (user.roles.superAdmin) {
          menuActions.push({
            title: 'Impersonate',
            icon: faUserShield,
            action: async () => {
              await API.users.impersonateUser(row.id);
            },
          });
        }

        if (user?.roles?.superAdmin || user?.rights?.user_edit || user?.rights?.user_create || user?.rights?.user_delete) {
          menuActions.push({
            title: t('change-login-data'),
            icon: faLock,
            action: () => {
              setResetPasswordOpen(true);
            },
          });
        }

        if (user?.roles?.superAdmin || user?.rights?.user_edit) {
          menuActions.push({
            title: t('can-edit-users'),
            icon: faEdit,
            action: (e) => {
              setEditUserPopupOpen(true);
            },
          });
        }

        return (
          <>
            <MainMenu
              id={row.id}
              menuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              }}
              actions={menuActions}
            />
            <ResetPasswordPopup user={row as Core.User} onClose={() => setResetPasswordOpen(false)} open={resetPasswordOpen} />
            <UserEdit open={editUserPopupOpen} onClose={() => setEditUserPopupOpen(false)} currentUser={row as Core.User} />
          </>
        );
      },
      filterable: false,
      disableColumnMenu: true,
    },
  };

  const deleteUsersMutation = useDeleteUsersMutation();
  useNotificationEffect(
    {
      title: t('error'),
      type: 'error',
      text: deleteUsersMutation.error?.message || deleteUsersMutation.error?.message,
    },
    deleteUsersMutation.isError,
    [deleteUsersMutation.error],
  );
  const notify = usePopup();
  const [invitationEmails, setInvitationEmails] = useState(false);
  const [filterModel, setFilterModel, fastFilterModel] = useDebouncedState<GridFilterModel>();
  const [openDeletePopup, setOpenDeletePopup] = useState(undefined);

  const selectedUsers: TSelectableUser[] = selection.map((userid) => {
    return usersList.find(u => u.id === userid) as TSelectableUser;
  });

  return (
    <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <ContentHeader noBack title={t('external-users')} subtitle={t('users-counted', { count: usersList?.length })}>
        <SendEmailToUsersPopup
          active={invitationEmails}
          selectedUsers={selectedUsers}
          setActive={setInvitationEmails}
          subject=""
          message=""
          sendEmail={async (to: string[], subject: string, message: string) => {
            try {
              await API.email.sendNotificationEmail({ emails: to }, subject, {
                emailTitle: subject,
                emailText: message,
                buttonLink: 'https://ibu-scope.com/',
                autosend: false,
              });
              setInvitationEmails(false);
              notify({ title: t('successfully-sent'), type: 'success', text: t('invitation-emails-send-successfully') });
            } catch (error) {
              notify({ title: t('error'), type: 'error', text: error });
            }
          }}
        />
        <Stack direction="row" spacing={2} flex={1} marginLeft={5} alignItems="center">
          <Box flex={1}>
            <SearchInput
              size="small"
              InputProps={{
                sx: {
                  background: theme.palette.background.default,
                  ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) },
                },
              }}
              placeholder={t('search')}
              fullWidth
              value={fastFilterModel?.items?.find((i) => i.columnField === 'displayName')?.value ?? ''}
              onChange={(e) => {
                try {
                  new RegExp(e.target.value);
                  setFilterModel({
                    items: [
                      { columnField: 'displayName', operatorValue: 'contains', value: e.target.value },
                      { columnField: 'groupNames', operatorValue: 'contains', value: e.target.value },
                    ],
                    linkOperator: GridLinkOperator.Or,
                  });
                } catch {}
              }}
            />
          </Box>
          <ConditionalRenderWithRights user_delete>
            <ConditionalRender render={selection.length > 0}>
              <LoadingButton
                sx={{ borderRadius: 999 }}
                onClick={() => {
                  setOpenDeletePopup(true);
                }}
                loading={deleteUsersMutation.isLoading}
                variant="contained"
                color="error"
                endIcon={<FontAwesomeIcon icon={faTrash} />}
              >
                {t('delete-users')}
              </LoadingButton>
              <MainDialog
                open={openDeletePopup}
                onCloseClick={() => setOpenDeletePopup(false)}
                onSaveClick={async () => {
                  await deleteUsersMutation.mutateAsync(selection);
                  setSelection([]);
                  setOpenDeletePopup(false);
                }}
                saveButtonColor="error"
                secondButtonColor="primary"
                modalTitle={t('do-you-really-want-to-delete-count-users', { count: selection.length })}
                description={'This action cannot be undone.' /* TODO: Implement i18n key */}
                buttonText={t('delete')}
              />
            </ConditionalRender>
          </ConditionalRenderWithRights>
          <ConditionalRenderWithRights user_create user_edit user_groups_edit>
            <Button
              sx={{ borderRadius: 999 }}
              onClick={() => {
                setInvitationEmails(true);
              }}
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={faMailBulk} />}
            >
              {t('send-mails')}
            </Button>
          </ConditionalRenderWithRights>
        </Stack>
      </ContentHeader>
      <XGrid
        className={classes.root}
        headerHeight={40}
        loading={isLoading}
        checkboxSelection
        sortModel={[{ field: 'displayName', sort: 'asc' }]}
        onFilterModelChange={({ filterModel }) => setFilterModel(filterModel)}
        filterModel={filterModel}
        disableSelectionOnClick
        onRowClick={(params) => setCreateUserPopupOpen(params.row as Core.User)}
        onSelectionModelChange={({ selectionModel }) => {
          setSelection(selectionModel);
        }}
        columns={Object.entries(columns)
          .map(([key, value]) => ({ field: key, ...value }))
          .map((c) => ({
            ...c,
            renderHeader: (params) => {
              return (
                <Typography variant="subtitle1" display="contents" style={{ alignSelf: 'center' }}>
                  {params.colDef.headerName}
                </Typography>
              );
            },
          }))}
        rows={searchOptimizedUsers}
        components={{
          NoRowsOverlay: GridNoRowsOverlay,
          NoResultsOverlay: GridNoResultsOverlay,
        }}
      />
      <UserEdit open={createUserPopupOpen} onClose={() => setCreateUserPopupOpen(null)} currentUser={createUserPopupOpen as Core.User} />
    </Box>
  );
};
