import { Actions } from '@base/core';
import { faCheckDouble, faCog, faFilePlus, faFileUpload, faFolderPlus, faFolderTree, faLink, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, IconButton, ListItemIcon, Menu, MenuItem, Stack, useTheme } from '@material-ui/core';
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ConditionalRender, ContentHeader, usePopup } from '../../components/common';
import { AccessLinkPopup } from '../../components/common/popups/AccessLinkPopup';
import { ContextMenu, ContextMenuElementType } from '../../components/common/popups/ContextMenu';
import { ContentElement, downloadFile, EmptyContentView, NoSearchResultContentView } from '../../components/content';
import { SortDropdown } from '../../components/content/search';
import { UploadPopup } from '../../components/content/Upload/UploadPopup';
import { SearchInput } from '../../components/inputs';
import { compareSearch, ContentContainer, ContentView, NavbarSmall } from '../../components/layouts';
import '../../css/Views/content/FileManager.scss';
import { ConditionalRenderWithRightsForFiles, useGetCombinedRightsForFile } from '../../helpers/ConditionalRenderWithRights';
import { useSelection } from '../../lib/hooks/useSelection';
import { BREADCRUMBS, BreadcrumbsType } from '../../router/BreadcrumbsType';
import { FileModule } from '../campaign/CampaignOverview';
import { ModuleSettingsPopup } from '../campaign/ModuleSettingsPopup';
import { CreateFolderPopup } from './CreateFolderPopup';
import { FolderSelectPopup } from './FolderSelectPopup';
import { useCreateSIDocumentMutation, useDeleteFileMutation, useMoveFilesMutation, useToggleFavoriteMutation, useUpdateFileMutation } from './hooks/mutations';
import { useGetChildren } from './hooks/useFolderChildren';
import { useGetFile } from './hooks/useGetFile';
import { useGetParents } from './hooks/useGetParents';
import {includes} from "lodash";

export interface Folder extends Core.VirtualFile {
  description?: string;
}

export const FileManager = () => {
  const { t } = useTranslation();
  const notify = usePopup();
  const [createFolderPopupVisible, setCreateFolderPopupVisible] = useState(false);
  const [movePopupVisible, setMovePopupVisible] = useState(false);
  const [filter, setFilter] = useState<'a-z' | 'z-a'>('a-z');
  const [linkPopupActive, setLinkPopupActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const { uploadTasks } = useSelector((state: Core.StateType) => state.files);
  const { fileId: parentId } = useParams<{ fileId: string }>();
  const queryClient = useQueryClient();
  const mutateFavorite = useToggleFavoriteMutation();
  const createSiDocMutation = useCreateSIDocumentMutation();
  const deleteFileMutation = useDeleteFileMutation();
  const updateMutation = useUpdateFileMutation();
  const moveMutation = useMoveFilesMutation();
  const [contextMenuItems, setContextMenuItems] = useState<ContextMenuElementType[]>([]);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number; mouseY: number }>({ mouseX: null, mouseY: null });

  useEffect(() => {
    void queryClient.invalidateQueries(['files', parentId, 'children']);
  }, [Object.values(uploadTasks).filter((t) => t.state === 'running').length]);

  const { user } = useSelector((state: Core.StateType) => state.auth);
  const { isLoading: folderLoading, isError: folderError, data: folder } = useGetFile(parentId);
  const { isLoading: childrenLoading, isError: childrenError, data: childrenData } = useGetChildren(parentId);
  const parents = useGetParents(folder?.path ?? []);
  const { children = [], fileCount = 0, folderCount = 0 } = childrenData && (folder?.permissions.visibility === 'public' || (folder?.permissions.visibility === 'private' && includes(folder?.permissions.users.read, user.id))) ? childrenData : {};
  const [relativeLink, setRelativeLink] = useState(parentId);
  const { selectAll, deSelectAll, onContentClick, selectedMap, selectedItemCount, setSelectedMap, selectedIds } = useSelection(children);

  // const { results, fetching, error } = useSearch({ query: searchValue, pageSize: 20, parent: parentId, searchOnEmptyQuery: false });

  // if (results)
  //   var {
  //     hits: { hits = [], total },
  //     took,
  //   } = results;
  // const searchedFiles = sortedFiles;

  const searchedElements = useMemo(() => children.filter((element) => compareSearch(searchValue, element.name)) ?? [], [children, searchValue]);

  const sortedFolders = useMemo(() => {
    const res = searchedElements.filter((folder) => folder.type === 'folder').sort((a, b) => (a.name ?? '').localeCompare(b.name));
    if (filter === 'z-a') return res.reverse();
    return res;
  }, [searchedElements, filter]);

  const sortedFiles = useMemo(() => {
    const res = searchedElements.filter((folder) => folder.type !== 'folder').sort((a, b) => (a.name ?? '').localeCompare(b.name));
    if (filter === 'z-a') return res.reverse();
    return res;
  }, [searchedElements, filter]);

  const noSearchResult = searchedElements?.length === 0 && searchValue.length > 0;

  const rootFolder = parents?.length > 0 ? parents[0]?.isSuccess && (parents[0].data as FileModule) : (folder as FileModule);

  const folderName = folder?.name ?? '';
  const fileRights = useGetCombinedRightsForFile(folder, rootFolder);

  const isFileFavorite = (file: Core.VirtualFile) => {
    return file.favoriteOf?.some((favoriteUsers) => favoriteUsers === user.id);
  };

  const deleteElement = (fileToDelte: Core.VirtualFile) => deleteFileMutation.mutate(fileToDelte);

  const createSIFile = async () => {
    const res = await createSiDocMutation.mutateAsync({ parent: parentId });
    history.push(`/files/${res.id}?edit`);
  };

  const openFolder = (id: string) => history.push(`/files/${id}`);

  const onContextMenuStart = (currentSelectedIs?: string[]): ContextMenuElementType[] => {
    const contextOptions: ContextMenuElementType[] = [];
    currentSelectedIs = currentSelectedIs ?? selectedIds;
    if (currentSelectedIs.length > 1) {
      contextOptions.push(
        {
          text: t('delete-all'),
          icon: faTrash,
          onClick: () => {
            currentSelectedIs.forEach((id) => {
              deleteElement(sortedFiles.find((file) => file.id === id));
            });
          },
        },
        {
          text: t('move-file'),
          icon: faFolderTree,
          onClick: () => setMovePopupVisible(true),
        },
      );
    } else {
      contextOptions.push(
        {
          text: t('select-all'),
          icon: faCheckDouble,
          onClick: () => {
            selectAll();
          },
        },
        {
          text: t('share-link'),
          icon: faLink,
          onClick: () => {
            setRelativeLink(parentId);
            setLinkPopupActive(!linkPopupActive);
          },
        },
      );
      if (fileRights.write) {
        contextOptions.push(
          {
            text: t('neues-si-dokument'),
            icon: faFilePlus,
            onClick: () => createSIFile(),
          },
          {
            text: t('upload-file'),
            icon: faFileUpload,
            onClick: () => fileInputRef.current.click(),
          },
          {
            text: t('new-folder'),
            icon: faFolderPlus,
            onClick: () => setCreateFolderPopupVisible(true),
          },
        );
      }
    }
    return contextOptions;
  };

  const onDrop = (acceptedFiles: File[]) => {
    console.log(acceptedFiles);

    let shouldReturn = false;
    acceptedFiles.some((file) => {
      sortedFiles.forEach((existingFile) => {
        if (file.name === existingFile.name) {
          notify({
            title: t('doublicate-file-title'),
            type: 'error',
            text: t('file-exists-text', { fileName: file.name }),
          });
          shouldReturn = true;
        }
      });
    });
    if (shouldReturn) return;

    dispatch(
      Actions().files.uploadFilesWithinFolders(acceptedFiles as (File & { path: string })[], parentId, {
        groups: {},
        users: {
          read: [user.id],
          write: [user.id],
          move: [user.id],
          permission: [user.id],
        },
        visibility: 'public',
      }),
    );
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const { onClick, ...DnDRootProps } = getRootProps();

  const uploadFileFromLokalFilespace = () => {
    const files: (File & { path: string })[] = [];
    const fileList = fileInputRef.current.files as FileList;
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      file['path'] = file.name;
      files.push(file as File & { path: string });
    }
    onDrop(files);
  };

  const handleFileMove = (newParent: string) => {
    moveMutation.mutate(selectedIds.map((id) => ({ fileId: id, oldParent: parentId, parent: newParent })));
  };

  const handleContextMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    setContextMenuItems(onContextMenuStart());
  };

  const [settingsPopupVisibility, setsettingsPopupVisibility] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function checkIfMulitpleSelected(ownId: string): boolean {
    if (selectedIds.length == 1) {
      return selectedIds[0] !== ownId;
    } else if (selectedIds.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  const extendedBreadcrumbs = [
    ...parents.filter((p) => p.data?.name !== folder?.name).map((p) => ({ path: `/files/${p.data?.id}`, name: p.data?.name } as BreadcrumbsType)),
    { name: folder?.name, path: `/files/${folder?.id}` },
  ] as BreadcrumbsType[];

  return (
    <ContentContainer>
      <AccessLinkPopup setActive={setLinkPopupActive} active={linkPopupActive} relativeLinkUrl={'/files/' + relativeLink} />
      <CreateFolderPopup onClose={() => setCreateFolderPopupVisible(false)} open={createFolderPopupVisible} parentId={parentId} />
      <NavbarSmall breadcrumbs={[BREADCRUMBS.home, BREADCRUMBS.moduleOverview, ...extendedBreadcrumbs]} />
      <UploadPopup />
      <ModuleSettingsPopup
        visible={settingsPopupVisibility}
        onClose={() => setsettingsPopupVisibility(null)}
        onUpdate={(u) => updateMutation.mutateAsync({ fileId: folder.id, update: u }) as any}
        onDelete={() => deleteFileMutation.mutateAsync(folder)}
        currentModule={folder as any}
      />
      <ContentView
        onClick={(event) => {
          event.stopPropagation();
          deSelectAll();
        }}
        loading={childrenLoading || folderLoading || moveMutation.isLoading}
        onContextMenu={(event) => handleContextMenuClick(event)}
        noPadding
      >
        <div style={{ zIndex: 3 }}>
          <ContentHeader title={folderName} subtitle={t('fileWithCount', { count: fileCount }) + ', ' + t('folderWithCount', { count: folderCount })} infoDescription={folder?.description}>
            <Stack direction="row" spacing={1} flex={1} marginLeft={3} alignItems="center">
              <Box flex={1}>
                <SearchInput
                  size="small"
                  InputProps={{ sx: { background: theme.palette.background.default, ':hover, & :focus': { background: alpha(theme.palette.background.default, 0.4) } } }}
                  placeholder={t('search')}
                  fullWidth
                  value={searchValue}
                  onChange={(e) => {
                    try {
                      new RegExp(e.target.value);
                      setSearchValue(e.target.value);
                    } catch {}
                  }}
                />
              </Box>
              <ConditionalRenderWithRightsForFiles parent={folder} root={rootFolder} write>
                <input style={{ display: 'none' }} ref={fileInputRef} type="file" name="filePicker" id="filepicker" onChange={uploadFileFromLokalFilespace} multiple />
                <div>
                  <IconButton
                    sx={{ color: 'secondary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999, zIndex: 2, ml: 1, width: 48 }}
                    onClick={handleMenuClick}
                    // ref={menuAnchorRef.current}
                  >
                    <FontAwesomeIcon icon={faPlus} color={theme.palette.primary.main} className="search-filter-img" />
                  </IconButton>
                  <Menu
                    sx={{ mt: 1 }}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem
                      onClick={async () => {
                        await createSIFile();
                        handleMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faFilePlus} color={theme.palette.primary.main} className="search-filter-img" />
                      </ListItemIcon>
                      {t('neues-si-dokument')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        fileInputRef.current.click();
                        handleMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faFileUpload} color={theme.palette.primary.main} className="search-filter-img" />
                      </ListItemIcon>
                      {t('upload-document')}
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        setCreateFolderPopupVisible(true);
                        handleMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faFolderPlus} color={theme.palette.primary.main} className="search-filter-img" />
                      </ListItemIcon>
                      {t('new-folder')}
                    </MenuItem>
                  </Menu>
                </div>
              </ConditionalRenderWithRightsForFiles>
              <ConditionalRenderWithRightsForFiles parent={folder} root={rootFolder} write>
                <ConditionalRender render={folder?.parent == null}>
                  <IconButton
                    sx={{ color: 'secondary.main', bgcolor: 'background.paper', ':hover': { bgcolor: 'action.hover' }, borderRadius: 999, zIndex: 2, ml: 1, width: 48 }}
                    onClick={() => setsettingsPopupVisibility(true)}
                    color="primary"
                  >
                    <FontAwesomeIcon icon={faCog} color={theme.palette.primary.main} />
                  </IconButton>
                </ConditionalRender>
              </ConditionalRenderWithRightsForFiles>
              <SortDropdown onChange={setFilter} />
            </Stack>
          </ContentHeader>
        </div>
        <ContextMenu
          menuItems={contextMenuItems}
          position={contextMenuPosition}
          onClose={() => {
            setContextMenuPosition({ mouseX: null, mouseY: null });
            setContextMenuItems([]);
          }}
        />
        <div
          style={{
            backgroundColor: 'transparent',
            position: 'fixed',
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            zIndex: isDragActive ? 99 : 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // pointerEvents: "none"
          }}
          {...DnDRootProps}
        ></div>
        <input {...getInputProps()} webkitdirectory directory />
        {/* {fetching ? (
          <Loading small />
        ) : ( */}
        <div>
          {fileCount === 0 && folderCount === 0 && !noSearchResult ? <EmptyContentView /> : null}
          {noSearchResult ? <NoSearchResultContentView /> : null}
          <Box sx={{ p: 4 }}>
            <div className="file-grid" /*style={searchValue ? { gridTemplateColumns: '1fr', paddingBottom: 50 } : {}*/>
              {sortedFolders.map((file) => (
                <ContentElement
                  fileRights={fileRights}
                  key={file.id}
                  file={file}
                  selected={selectedMap[file.id]}
                  favorite={isFileFavorite(file)}
                  setFavorite={async () => await mutateFavorite.mutateAsync(file.id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    onContentClick(file, e);
                  }}
                  onDoubleClick={() => {
                    openFolder(file.id);
                  }}
                  setContextMenuItems={(fileItems, menuPosition) => {
                    if (checkIfMulitpleSelected(file.id)) {
                      setContextMenuItems(onContextMenuStart([...selectedIds, file.id]));
                    } else {
                      setContextMenuItems(fileItems);
                    }
                    setSelectedMap({ ...selectedMap, [file.id]: true });
                    setContextMenuPosition(menuPosition);
                  }}
                  onFileMove={() => setMovePopupVisible(true)}
                />
              ))}
              {sortedFiles.map((file) => (
                <ContentElement
                  fileRights={fileRights}
                  key={file.id}
                  file={file}
                  selected={selectedMap[file.id]}
                  favorite={isFileFavorite(file)}
                  setFavorite={async () => await mutateFavorite.mutateAsync(file.id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    onContentClick(file, e);
                  }}
                  onDoubleClick={async (e) => {
                    if (file.type === 'si/document') {
                      if (user?.roles?.superAdmin || file.permissions.users.read.some((uid) => uid === user.id)) {
                        history.push(`/files/${file.id}?edit`);
                      } else {
                        history.push(`/files/${file.id}`);
                      }
                    } else {
                      file.type === 'application/pdf' ?
                        window.open(await (file as Core.NormalFile).downloadUrl, '_blank') :
                        void downloadFile(file as Core.NormalFile);
                    }
                  }}
                  setContextMenuItems={(fileItems, menuPosition) => {
                    if (checkIfMulitpleSelected(file.id)) {
                      setContextMenuItems(onContextMenuStart([...selectedIds, file.id]));
                    } else {
                      setContextMenuItems(fileItems);
                    }
                    setSelectedMap({ ...selectedMap, [file.id]: true });
                    setContextMenuPosition(menuPosition);
                  }}
                  onFileMove={() => setMovePopupVisible(true)}
                />
              ))}
              {/* {searchValue.length > 0 && hits?.length > 0 ? (
                hits.map((hit, i) => (
                  <ContentElementSearch
                    key={hit._id}
                    id={hit._id}
                    name={hit.highlight?.['name']?.[0] || (hit.fields.name?.[0] ?? '')}
                    selected={false}
                    //@ts-ignore
                    type={(hit.fields?.['documentData.content_type']?.[0] || hit.fields.type?.[0]) ?? 'unknown'}
                    highlights={hit.highlight?.['documentData.content'] ?? []}
                    onClick={() => null}
                    onDoubleClick={() => {
                      if (hit.fields.downloadUrl) window.open(hit.fields.downloadUrl[0], '_blank');
                      else openFolder(hit._id);
                    }}
                  />
                ))
              ) : searchValue.length > 0 ? (
                <NoSearchResultContentView />
              ) : null}
              {error && <h1>{error}</h1>} */}
            </div>
          </Box>
        </div>
        {/* )} */}
      </ContentView>
      {isDragActive && (
        <div
          style={{
            backgroundColor: '#000a',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // width: 150,
            pointerEvents: 'none',
            zIndex: 9999,
            color: 'white',
          }}
        >
          <FontAwesomeIcon icon={faFilePlus} style={{ fontSize: 50, color: '#fff' }} />
          <h1 style={{ color: '#fff', fontSize: 30 }}>{t('drop-here')}</h1>
        </div>
      )}
      <FolderSelectPopup setVisible={setMovePopupVisible} visible={movePopupVisible} initialParent={parentId} onFolderSelected={handleFileMove} excludeFolderIds={selectedIds} resetOnVisible mimeTypes={['folder']}/>
    </ContentContainer>
  );
};
