import {API, ApplicationForm, Campaign, ChatItem, FormSubmission} from '@base/core';
import {FileProvider, Form, FormContent, FormViewer, FormViewerPDF, FormViewerResponse} from '@editors/form-editor';
import { faBallotCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faInfoCircle, faPrint, faSave, faSpinnerThird, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, Button, CircularProgress, Skeleton, Stack, Theme, Toolbar, Tooltip, Typography, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import { styled } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { usePopup } from '../../components/common';
import { useObservable } from 'rxjs-hooks';
import { MainDialog } from '../../components/common/popups/MainDialog';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { useSelector } from 'react-redux';
import { sendChatNotification } from './hooks/mutations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarRoot: {
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[6],
    },
  }),
);

const IconButtonRect = withStyles({
  root: {
    padding: '8px !important',
    minWidth: '46px !important',
    marginLeft: '12px !important',
  },
  startIcon: {
    marginRight: '-4px !important',
    '& :nth-of-type(1)': {
      fontSize: '28px !important',
    },
  },
})(Button);

interface FormSubmissionViewerProps {
  onSave(formSubmission: FormSubmission, create?: boolean): any | Promise<any>;
  onSubmit(formSubmission: FormSubmission, initialSubmit: boolean): any | Promise<any>;
  onClose(): void;
  campaignName: string;
  isLoading?: boolean;
  form?: ApplicationForm;
  onDelete(formId: string): void | Promise<void>;
  fileProvider: FileProvider;
  fileUploadProvider: FileProvider;
  campaign: Campaign;
  value: FormSubmission;
}

const Offset = styled('div')(({ theme }: any) => theme.mixins.toolbar);

export function FormSubmissionViewer({ campaignName, form: document, onClose, onSave, fileProvider, isLoading, onDelete, fileUploadProvider, value, onSubmit, campaign }: FormSubmissionViewerProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const user = useSelector((state: Core.StateType) => state.auth.user);
  const { t } = useTranslation();

  const formViewerRef = useRef<{ print: () => void }>(null);
  const notify = usePopup();

  const [savePending, setSavePending] = useState(false);
  const [informationPopup, setInformationPopup] = useState(false);

  const valueRef = useRef();

  const handleSave = async () => {
    try {
      setSavePending(true);
      await onSave({ data: valueRef.current, state: value?.state == 'submitted' ? 'submitted' : 'draft' } as any);
      notify({
        title: t('saved'),
        type: 'success',
        time: 1500,
      });
    } catch (error) {
      notify({
        title: 'Error',
        type: 'error',
        text: error.message,
      });
    } finally {
      setSavePending(false);
    }
  };
  const history = useHistory();

  const handleSubmit = async (content: any) => {
    try {
      setSavePending(true);
      await onSubmit({ data: content, state: 'submitted' } as any, value?.state === 'draft');
      notify({
        title: t('submitted'),
        text: 'The form has been sent to IBU. You can now close the form.',
        type: 'success',
      });
      history.goBack();
    } catch (error) {
      notify({
        title: 'Error',
        type: 'error',
        text: error.message,
      });
    } finally {
      setSavePending(false);
    }
  };

  const renderToEmailText = (message) => {
    let retVal = '';

    message.content.forEach((c) => {
      if (c.type === 'paragraph') {
        c.content?.forEach((t) => {
          let bold = false;
          let cursive = false;
          let textColor;
          let word = t.text;

          if (t.marks) {
            t.marks.forEach((m) => {
              if (m.type === 'strong') {
                bold = true;
              } else if (m.type === 'em') {
                cursive = true;
              } else if (m.type === 'textColor') {
                textColor = m.attrs.color;
              }
            });
          }

          if (bold) {
            word = '<strong>' + word + '</strong>';
          }
          if (cursive) {
            word = '<em>' + word + '</em>';
          }
          if (textColor) {
            word = '<span style="color:' + textColor + '" >' + word + '</span>';
          }

          retVal += word;
        });

        retVal += '<br>';
      }
    });
    return retVal;
  }

  const handleSendChat = async (message: FormContent, questionId: string) => {
    try {
      await API.chats.createChatItem(chatId, {
        creator: user.id,
        text: message,
        questionId,
      });
      try {
        await sendChatNotification(campaign, user, document.name, renderToEmailText(message), document.content.find((c) => c.id === questionId)?.title);
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      notify({
        title: t('error'),
        type: 'error',
        text: error.message || error,
      });
    }
  };
  const handleReadChatItems = (chatItems: string[]) => {
    void API.chats.readChatItems(chatId, chatItems, user.id);
  };
  const chatId = 'formsubmissions:' + value?.id;
  const chats = useObservable<ChatItem[], [string]>((_, $inputs) => $inputs.pipe(switchMap(([chatId]) => API.chats.getChatObservable(chatId))), [], [chatId]);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <AppBar>
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <Stack margin={2} direction="row" spacing={2} flex={1} alignItems="center">
            <FontAwesomeIcon icon={faBallotCheck} size="4x" color={theme.palette.primary.light} />
            <Box>
              <Typography variant="h3">{isLoading ? <Skeleton width={200} /> : document.name || 'Untitled'}</Typography>
            </Box>
            <Box flex={1} />
            <Tooltip title={t('basic-information')}>
              <IconButtonRect
                size="medium"
                startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                style={{ color: theme.palette.info.main, backgroundColor: theme.palette.info.lighter, height: 46, width: 46 }}
                variant="contained"
                onClick={() => {
                  setInformationPopup(true);
                }}
              />
            </Tooltip>
            <Tooltip title={t('print')}>
              <IconButtonRect
                size="medium"
                startIcon={<FontAwesomeIcon icon={faPrint} />}
                style={{ color: theme.palette.info.main, backgroundColor: theme.palette.info.lighter, height: 46, width: 46 }}
                variant="contained"
                onClick={async () => {
                  formViewerRef.current.print();
                }}
              />
            </Tooltip>
            <Tooltip title={t('close')}>
              <IconButtonRect
                size="medium"
                startIcon={<FontAwesomeIcon icon={faTimes} />}
                style={{ color: theme.palette.error.main, backgroundColor: theme.palette.error.lighter, height: 46, width: 46 }}
                variant="contained"
                onClick={async () => {
                  onClose();
                }}
              />
            </Tooltip>
            {(value?.state || 'draft') === 'draft' && !document?.locked && (
              <Tooltip title={t('save')}>
                <IconButtonRect
                  size="medium"
                  disabled={savePending}
                  startIcon={<FontAwesomeIcon icon={savePending ? faSpinnerThird : faSave} spin={savePending} />}
                  style={{ color: theme.palette.success.main, backgroundColor: theme.palette.success.lighter, height: 46, width: 46 }}
                  variant="contained"
                  onClick={handleSave}
                />
              </Tooltip>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
      <MainDialog
        open={informationPopup}
        onCloseClick={() => setInformationPopup(false)}
        hideCancel
        onSaveClick={() => {
          setInformationPopup(false);
        }}
        modalTitle={t('basic-information')}
        description={document?.description}
        buttonText={t('close')}
      />
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" mt={10}>
          <CircularProgress />
        </Box>
      ) : value?.state && value?.state !== 'draft' && value?.state !== 'on-hold' || document.locked ? (
        <FormViewerResponse
          status={value?.state ?? ''}
          document={document}
          fileProvider={fileProvider}
          uploadFileProvider={fileUploadProvider}
          canSubmit={value?.state !== 'success' && user.rights.groupRights?.group_admin?.length > 0}
          onSubmit={handleSubmit}
          value={value?.data}
          review={value?.review}
          valueRef={valueRef}
          onSendChat={handleSendChat}
          readChatItems={handleReadChatItems}
          readOnly={document.locked}
          onMissingFieldsOnPage={() => {
            notify({
              title: 'Error',
              type: 'error',
              text: 'Some required fields are missing',
            });
          }}
          chats={chats as any}
          onSave={async (value) => {
            try {
              setSavePending(true);
              await onSave({ data: valueRef.current, state: value?.state === 'submitted' ? 'submitted' : 'draft' } as any);
              notify({
                title: t('saved'),
                type: 'success',
                time: 1500,
              });
            } catch (error) {
              notify({
                title: 'Error',
                type: 'error',
                text: error.message,
              });
            } finally {
              setSavePending(false);
            }
          }}
        />
      ) : (
        <FormViewer
          document={document}
          fileProvider={fileProvider}
          uploadFileProvider={fileUploadProvider}
          onSubmit={handleSubmit}
          disableSubmit={!(value?.state !== 'success' && user.rights.groupRights?.group_admin?.length > 0)}
          value={value?.data}
          valueRef={valueRef}
          onMissingFieldsOnPage={() => {
            notify({
              title: 'Error',
              type: 'error',
              text: 'Some required fields are missing',
            });
          }}
          onSave={async (value) => {
            try {
              setSavePending(true);
              await onSave({ data: valueRef.current, state: value?.state === 'submitted' ? 'submitted' : 'draft' } as any);
              notify({
                title: t('saved'),
                type: 'success',
                time: 1500,
              });
            } catch (error) {
              notify({
                title: 'Error',
                type: 'error',
                text: error.message,
              });
            } finally {
              setSavePending(false);
            }
          }}
        />
      )}
      {value && <FormViewerPDF document={document} uploadFileProvider={fileProvider} value={value} ref={formViewerRef} showInformationFields={false}/>}
    </Box>
  );
}
