import { RegisterRequestPopup } from '@access-request';
import { Actions } from '@base/core';
import { faEnvelope, faLock, faSignIn, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Box, Button, FormControl, Link, MenuItem, Select, Stack, Typography} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContainer, AuthSlide, AuthSlider, LeftContainer, RightContainer } from '../../components/auth';
import { TwoFactorSuccessPopup } from '../../components/auth/2fa/TwoFactorSuccessPopup';
import {DefaultDropDown, useNotificationEffect} from '../../components/common';
import { TextInputIcon } from '../../components/inputs';
import '../../css/Views/auth/login.scss';
import { NamedGroup } from '../../theme/Theme';
import { useTheme } from '../../theme/ThemeProvider';
import { Loading } from './Loading';
import {Roles} from "../../components/users";
export const Login = () => {
  const theme = useTheme();
  const { fetching: fetchingState } = useSelector((state: Core.StateType) => state.auth);

  const { error, fetching, success } = fetchingState.login;
  const { fetching: autologinFetching } = fetchingState.tryAutoLogin;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const login = () => dispatch(Actions().auth.login(email, password, undefined));
  const { t } = useTranslation();

  useNotificationEffect({ text: error, title: t('login-failed'), type: 'error' }, !success && error, [success, error]);

  const groups = theme.namedGroups.filter((g) => g.accessRequest);

  const [externalGroup, setExternalGroup] = useState('federation');

  if (fetching || autologinFetching) {
    return <Loading />;
  } else {
    return (
      <div className="login">
        <AuthContainer>
          <TwoFactorSuccessPopup />
          <LeftContainer title="Login" icon={faSignIn} customerLogo={theme.images.logo} productName={t('product-name')}>
            <div className="form-block w-form">
              <form
                onSubmit={(e) => {
                  login();
                  e.preventDefault();
                }}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="login-form"
              >
                <TextInputIcon type="email" onChange={setEmail} placeholder={t('email')} value={email} icon={faEnvelope} />
                <TextInputIcon type="password" onChange={setPassword} password placeholder={t('password')} value={password} icon={faLock} />
                <Button variant="contained" fullWidth type="submit" value="login" id="2fa-login-btn">
                  {t('login')}
                </Button>
              </form>
              <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Link href="/reset-password" variant="subtitle2">
                  {t('Passwort vergessen')}
                </Link>
              </Box>
            </div>
            {theme.productSettings.accessRequest && groups.length > 0 && (
              <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-start', marginTop: 40, width: '100%' }}>
                <div className="row" style={{ marginBottom: 15 }}>
                  <FontAwesomeIcon icon={faUserPlus} fixedWidth />
                  <Typography variant="h4" sx={{ ml: 1 }}>
                    {t('registration')}
                  </Typography>
                </div>
                <Stack spacing={1} paddingBottom={1} alignItems="stretch" width="100%">
                  <Select
                    value={externalGroup}
                    onChange={({target: {value, name}}) => {
                      setExternalGroup(value as string);
                    }}
                    variant="outlined"
                    style={{
                      maxHeight: '2.5rem'
                    }}
                    defaultValue={externalGroup}
                  >
                    {groups.map((g) => (
                      <MenuItem value={g.name} selected={g.name === externalGroup}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                          <span style={{fontWeight: 'bold', color: '#023850'}}> {t(g.name)} </span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                  <AccessRequestButton group={groups.find(g => g.name === externalGroup)}></AccessRequestButton>
                </Stack>
              </div>
            )}
          </LeftContainer>
          <RightContainer>
            <AuthSlider>
              {theme.slides.loginSlides.map((slide) => (
                <AuthSlide title={slide.title} key={slide.title + slide.description} description={slide.description} illustration={slide.illustration} />
              ))}
            </AuthSlider>
          </RightContainer>
        </AuthContainer>
      </div>
    );
  }
};

function AccessRequestButton({ group }: { group: NamedGroup }) {
  const [requestPopupOpen, setRequestPopupOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <RegisterRequestPopup
        active={requestPopupOpen}
        setActive={setRequestPopupOpen}
        topic={t('registration')}
        name={t('ibu-application-platform')}
        description={t('this-is-the-registration-form-for-national-federations-associating-with-ibu-project')}
        FormContent={group.accessRequest.FormContent}
        getInitialValues={group.accessRequest.getInitialValues}
        getSchema={group.accessRequest.getSchema}
        type={group.name}
      />
      <Button variant="contained" onClick={() => setRequestPopupOpen(true)} fullWidth>
        {t('register')}
      </Button>
    </>
  );
}
