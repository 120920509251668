import {Typography, useTheme, Stack, Box, Grid} from '@material-ui/core';
import React from 'react';
import { ChatItem } from '@base/core';
import { UserImageHandler } from '@base/web';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import {InformationReader} from "./components/InformationReader";
import {Editor as CFEditor} from "@atlaskit/editor-core";
import {FormContent} from "@editors/form-editor";

export function ChatMessage({ chat, user }: { chat: ChatItem; user?: Core.User }) {
  const me = useSelector((state: Core.StateType) => state.auth.user);
  const isInternal = !user?.rights?.is_group_only_user;
  const theme = useTheme();
  const isSelf = me.id == user?.id;

  const color = isInternal ? theme.palette.primary.main : theme.palette.success.main;
  const direction = isSelf ? 'rtl' : 'ltr';

  return (
    <Stack sx={{ borderLeft: !isSelf ? color + ' 2px solid' : undefined, borderRight: isSelf ? color + ' 2px solid' : undefined, px: 1, direction }}>
      <Stack direction="row" alignItems="center">
        <UserImageHandler userName={user?.displayName} userPhotoUrl={user?.photoURL} fontSize={12} style={{ width: 20, height: 20 }} />
        <Typography variant="subtitle2" sx={{ px: 1 }}>
          {user?.displayName}
        </Typography>
      </Stack>
      <Box sx={{ width: 'max-content', maxWidth: '90%', paddingTop: '2px' }}>
        {(chat.text as FormContent).content ?
          <>
            <Grid item xs={12}>
              <CFEditor
                defaultValue={chat.text}
                key={'EDITORKEY'}
                appearance={'chromeless'}
                allowFindReplace
                allowHelpDialog
                allowDate
                disabled
                allowTextAlignment
                codeBlock={{allowCopyToClipboard: true, useLongPressSelection: true}}
                allowExpand={{allowInsertion: true, allowInteractiveExpand: true}}
                allowLayouts={{UNSAFE_addSidebarLayouts: true, allowBreakout: true, useLongPressSelection: true}}
                allowPanel
                allowNewInsertionBehaviour
                waitForMediaUpload
                allowNestedTasks
                allowTables={{advanced: true, allowColumnSorting: true, stickyHeaders: true}}
                allowStatus
                allowTasksAndDecisions
                quickInsert
                allowTextColor={{EXPERIMENTAL_allowMoreTextColors: true}}
                allowIndentation
                allowDynamicTextSizing
                allowKeyboardAccessibleDatepicker
                allowRule
                mentionInsertDisplayName
                shouldFocus
              />
            </Grid>
          </>
          :
          <>
            {(chat.text as string).split('\n').map((t, i) => (
              <Typography sx={{direction: 'ltr', fontWeight: 600}} key={i} variant="subtitle2">
                {t}
              </Typography>
            ))}
          </>
        }
      </Box>
      <Typography
        variant="subtitle2"
        style={{
          fontSize: '12px',
        }}
        color="grey.600"
      >
        {(isInternal ? 'IBU - ' : '') + format(chat.dateSent, 'hh:mm')}
      </Typography>
    </Stack>
  );
}
