import { OrganizingCommitteeAccessRequestFormData } from '@base/core';

export function getInitialValues<T extends OrganizingCommitteeAccessRequestFormData>(formData?: T): T {
  return {
    email: formData?.email ?? '',
    phoneNumber: formData?.phoneNumber ?? '',
    displayName: formData?.displayName ?? '',
    presidentEmail: formData?.presidentEmail ?? '',
    presidentPhoneNumber: formData?.presidentPhoneNumber ?? '',
    presidentName: formData?.presidentName ?? '',
    address: formData?.address ?? '',
    zipCode: formData?.zipCode ?? '',
    country: formData?.country ?? '',
    organizationName: formData?.organizationName ?? '',
    position: formData?.position ?? '',
    bankAccountPurpose: formData?.bankAccountPurpose ?? '',
    bankAccountName: formData?.bankAccountName ?? '',
    bankName: formData?.bankName ?? '',
    iban: formData?.iban ?? '',
    bic: formData?.bic ?? '',
    bankAccountPurpose2: formData?.bankAccountPurpose2 ?? '',
    bankAccountName2: formData?.bankAccountName2 ?? '',
    bankName2: formData?.bankName2 ?? '',
    iban2: formData?.iban2 ?? '',
    bic2: formData?.bic2 ?? '',
  } as any;
}
