import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileHandlerProps } from './FileHandlerProps';
import { MAX_FILE_SIZE_IN_BYTE } from './RequestForm';

export function FileHandler({ setFileSlots, disabled, deleteFile, fileSlot }: FileHandlerProps) {
  const { t } = useTranslation();

  function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>): void {
    const newFiles = Array.from(e.target.files);
    if (newFiles[0]) {
      setFileSlots((oldFileSlots) => [...oldFileSlots, { rawFileData: newFiles[0] }]);
    }
  }

  return (
    <Paper variant={'outlined'} className="upload-con">
      <input accept=".pdf" id="contained-button-file" type="file" onChangeCapture={handleFileSelected} hidden />
      {fileSlot.rawFileData ? (
        fileSlot.rawFileData.size > MAX_FILE_SIZE_IN_BYTE ? (
          <div>
            <Typography variant="subtitle1" color="error">
              {t('file-size-over-mb_size', { size: MAX_FILE_SIZE_IN_BYTE / 1000000 })}
            </Typography>
            <Typography variant="body1" color="error">
              {t('please-choose-smaller-document-or-compress-the-current-on')}
            </Typography>
          </div>
        ) : (
          <Typography variant="body1">{fileSlot.rawFileData.name}</Typography>
        )
      ) : (
        <div>
          <Typography variant="body1" fontWeight="700">
            {fileSlot.existingData?.name || (fileSlot.description ?? t('upload-new-file'))}
          </Typography>
        </div>
      )}
      <div className="flex-con">
        <label htmlFor="contained-button-file">
          {!fileSlot.rawFileData && !fileSlot.existingData && (
            <Button variant="contained" color="secondary" component="span" disabled={disabled}>
              {t('upload-file')}
            </Button>
          )}
        </label>
        {fileSlot.rawFileData && (
          <IconButton
            aria-label="delete"
            onClick={() => {
              setFileSlots((oldFileSlots) => oldFileSlots.filter((fs) => !(fs.rawFileData?.name === fileSlot.rawFileData?.name)));
            }}
            color="primary"
            sx={{ color: 'error.main', backgroundColor: 'error.lighter', ':hover': { bgcolor: 'error.main', color: 'white' }, width: 32, height: 32 }}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth style={{ fontSize: 16 }} />
          </IconButton>
        )}
        {fileSlot.existingData && (
          <>
            <IconButton
              sx={{ color: 'error.main', backgroundColor: 'error.lighter', ':hover': { bgcolor: 'error.main', color: 'white' }, width: 32, height: 32 }}
              aria-label="delete"
              onClick={() => deleteFile(fileSlot.existingData)}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth style={{ fontSize: 16 }} />
            </IconButton>
            { !fileSlot.existingData?.downloadUrl ??
              <a href={fileSlot.existingData?.downloadUrl} target="_blank">
              <Button variant="contained" color="secondary" disabled={disabled}>
                {t('download')}
              </Button>
            </a>
            }
          </>
        )}
      </div>
    </Paper>
  );
}
