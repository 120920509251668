import { Typography, Stack, TextField, IconButton, Box, Divider, Button, Collapse, StackProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatItem } from '@base/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useGetUsers } from 'libs/base/web/src/views/users/hooks/useGetUsers';
import { TransitionGroup } from 'react-transition-group';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { faTriangle } from '@fortawesome/pro-solid-svg-icons';
import { getFirstChatToShowIndex } from './Helper';
import { ChatMessage } from './ChatMessage';
import { ChangeMessage } from './ChangeMessage';
import { ConfluenceEditor } from "./components";
import cuid from "cuid";
import { FormContent } from "@editors/form-editor";

export function Comments({
  onSend,
  chats = [],
  readChatItems,
  full,
  containerProps = {},
  writeMessageContainerProps = {},
  readOnly
}: {
  onSend: (message: FormContent) => void;
  chats: ChatItem[];
  readChatItems: (chatItemIds: string[]) => void;
  full?: boolean;
  containerProps?: StackProps;
  writeMessageContainerProps?: StackProps;
  readOnly?: boolean;
}) {
  const me = useSelector((state: Core.StateType) => state.auth.user);
  const [text, setText] = useState({
    id: cuid(),
    contentType: 'rich-text',
    required: false,
  } as FormContent);
  const { data: users = [] } = useGetUsers();
  const sortedChats = chats.sort((a, b) => a.dateSent.valueOf() - b.dateSent.valueOf());

  const [firstChatToShowIndex, setFirstChatToShowIndex] = useState(() => getFirstChatToShowIndex(sortedChats, me.id) - 1);

  const handleSend = () => {
    onSend(text.content);
  };

  const { t } = useTranslation();
  useEffect(() => {
    readChatItems(chats.filter((c) => !(c as any).type && !c.read[me.id]).map((c) => c.id));
  }, [me]);

  const chatsToRender = full ? sortedChats : sortedChats.slice(Math.max(0, firstChatToShowIndex));

  const loadMore = () => {
    setFirstChatToShowIndex((s) => s - 5);
  };

  const transformToReadableText = (formContent) => {
    setText(formContent);
  };

  return (
    <Stack spacing={2} mt={2} {...containerProps}>
      {chatsToRender.length < chats.length && (
        <Button size="small" onClick={loadMore}>
          <Typography fontWeight="500" variant="caption" sx={{ color: 'grey.400', fontWeight: 600 }}>
            <FontAwesomeIcon icon={faTriangle} style={{ width: '0.7em' }} /> Show previous replies
          </Typography>
        </Button>
      )}
      <Stack flex={full && 1} overflow={'auto'} direction="column-reverse" paddingRight={1} marginRight={-1}>
        <TransitionGroup>
          {!chatsToRender.length && <Typography>No chats yet</Typography>}
          {chatsToRender.map((c, i, array) => {
            const shouldRenderDateSeparator = !array[i - 1] || array[i - 1].dateSent.getUTCDate() !== c.dateSent.getUTCDate();

            return (
              <Collapse key={c.id}>
                {shouldRenderDateSeparator && (
                  <>
                    <Typography textAlign="center" variant="overline" color="#858585">
                      {format(c.dateSent, t('full-date-mask'))}
                    </Typography>
                    <Divider style={{ marginTop: 2, marginBottom: 8 }} />
                  </>
                )}
                {(c as any).type !== 'changeset' ? <ChatMessage chat={c} user={users.find((u) => u.id == c.creator)} /> : <ChangeMessage chat={c} />}
                <Box paddingBottom={2} />
              </Collapse>
            );
          })}
        </TransitionGroup>
      </Stack>
      <Stack spacing={1} direction="row" alignItems="center" {...writeMessageContainerProps}>
        <Box flex={1}>
          <ConfluenceEditor value={text} onChange={(e) => transformToReadableText(e)} focused={true} disabled={readOnly} />
        </Box>
        <IconButton disabled={readOnly} sx={{ color: 'white', bgcolor: 'primary.main', ':hover': { bgcolor: 'primary.light', color: 'white' }, width: 38, height: 38 }} onClick={text ? handleSend : undefined}>
          <FontAwesomeIcon style={{ width: 16, height: 16 }} icon={faShare} />
        </IconButton>
      </Stack>
    </Stack>
  );
}
