import { faPlusCircle, faSave, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ConditionalRender, StandardPopup, useNotificationEffect, usePopup } from '../../components/common';
import { MainDialog } from '../../components/common/popups/MainDialog';
import '../../css/Views/admin/users/UsersOverride.scss';
import '../../css/Views/contentView.scss';
import { ConditionalRenderIfOwn } from '../../helpers/ConditionalRenderWithRights';
import { useTheme } from '../../theme/ThemeProvider';
import { UserEditUserContent } from '../users/UserEditUserContent';
import { containsRight } from './containsRight';
import { useCreateUserExternalMutation, useDeleteUserExternalMutation, useSetUserRightsExternalMutation, useUpdateUserExternalMutation } from './hooks/mutations';
import { UserEditAdminContentExternal } from './UserEditAdminContentExternal';
import { Actions } from "@base/core";

function removeUndefined<T extends object>(val: T): T {
  if (!val) return val;
  for (const key of Object.keys(val)) {
    //@ts-ignore
    if (val[key] == undefined)
      //@ts-ignore
      delete val[key];
  }
  return val;
}

export const UserEditExternal = ({ currentUser, onClose, open, rights, groupId }: { currentUser?: Core.User; onClose: () => void; open: boolean; rights: Core.GroupRights; groupId: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useSelector((state: Core.StateType) => state.auth);
  const dispatch = useDispatch();

  const compareStateUser = currentUser;

  const userUpdateMutation = useUpdateUserExternalMutation();
  const userDeleteMutation = useDeleteUserExternalMutation();
  const userCreateMutation = useCreateUserExternalMutation();
  const setUserRightsExternalMutation = useSetUserRightsExternalMutation();
  const [openDeletePopup, setOpenDeletePopup] = useState(undefined);

  useNotificationEffect({ title: t('error'), type: 'error', text: userCreateMutation.error?.message }, userCreateMutation.isError, [userCreateMutation.error]);
  useNotificationEffect({ title: t('error'), type: 'error', text: userUpdateMutation.error?.message }, userUpdateMutation.isError, [userUpdateMutation.error]);
  useNotificationEffect({ title: t('error'), type: 'error', text: userDeleteMutation.error?.message }, userDeleteMutation.isError, [userDeleteMutation.error]);

  const deleteUser = () => {
    userDeleteMutation.mutate(currentUser.id);
  };

  const updateUser = async ({ newPassword: password, rights, grouprights, roles, ...update }: Core.User & { newPassword?: string; userImage: Blob; grouprights: Core.GroupRights }) => {
    let userUpdate: Core.User;

    Object.entries(update).forEach((val) => {
      if (compareStateUser[val[0]] !== val[1]) {
        userUpdate = { ...userUpdate, [val[0]]: val[1] };
      }
    });
    const promises: any[] = [];

    if (compareStateUser.rights?.groupRights?.[groupId] !== grouprights) {
      userUpdate = { ...userUpdate };
      promises.push(setUserRightsExternalMutation.mutateAsync({ uid: currentUser.id, rights: grouprights }));
    }

    // if (userUpdate.currentModules ? userUpdate.currentModules.length === 0 : true) {
    //   userUpdate.currentModule = '';
    // }

    await userUpdateMutation.mutateAsync({
      id: currentUser.id,
      ...removeUndefined(userUpdate),
      ...(password !== '' && { password }),
    });

    if (user.id === currentUser.id && password && password !== '') {
      dispatch(Actions().auth.logout());
    }

    await Promise.all(promises);
    onClose();
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(t('enter-a-valid-email'))
      .required(t('something-is-required', { something: t('email') })),
    displayName: yup.string().required(t('something-is-required', { something: t('name') })),
    // ...(currentUser ? {} : { newPassword: yup.string().required(t('something-is-required', { something: t('password') })) }),
    phoneNumber: yup.string(),
    address: yup.string(),
    abteilung: yup.string(), //.required(t('something-is-required', { something: t('position') })),
  });

  const adminUser = rights.user_create || rights.user_delete || rights.user_edit || rights.user_right_edit || user.id == currentUser?.id;

  const initialValues = useMemo(() => {
    const defaultValues = ({
      displayName: '',
      phoneNumber: '',
      mobile: '',
      address: '',
      email: '',
      abteilung: '',
      rights: {},
      roles: {},
      currentModules: [],
      language: 'en',
      grouprights: {},
      notificationChannels: [],
    } as any) as Core.User;
    if (currentUser) return { ...defaultValues, ...currentUser, grouprights: currentUser.rights?.groupRights };
    return defaultValues;
  }, [currentUser]);
  return (
    <StandardPopup visible={open} onBackdropClick={onClose} width={1000}>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (currentUser) {
              await updateUser(values as any);
            } else {
              const { newPassword, ...rest } = values as any;
              await userCreateMutation.mutateAsync({ ...values, password: newPassword, group: groupId });
            }
            onClose();
          } catch {}
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {(formik) => (
          <Card>
            <CardContent>
              {adminUser && <UserEditAdminContentExternal formik={formik as any} inCreation={currentUser === undefined} rights={rights} groupId={groupId} />}
              {currentUser && !adminUser && <UserEditUserContent currentUser={currentUser} />}
              <CardActions sx={{ justifyContent: 'flex-end', mt: 1 }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('cancel')}
                </Button>
                <ConditionalRender render={Boolean(currentUser)}>
                  <ConditionalRender render={containsRight(rights, groupId, 'user_delete')}>
                    <ConditionalRenderIfOwn enableOwnAccountWithUid={currentUser?.id} invert>
                      <LoadingButton variant="contained" loading={userDeleteMutation.isLoading} color="error" onClick={() => setOpenDeletePopup(true)} startIcon={<FontAwesomeIcon icon={faTrash} />}>
                        {t('delete-user')}
                      </LoadingButton>
                    </ConditionalRenderIfOwn>
                  </ConditionalRender>
                  <ConditionalRender render={containsRight(rights, groupId, 'user_edit')}>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      onClick={formik.submitForm}
                      disabled={formik.isSubmitting}
                      loading={formik.isSubmitting}
                      startIcon={<FontAwesomeIcon icon={faSave} />}
                    >
                      {t('save')}
                    </LoadingButton>
                  </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender render={!currentUser}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={formik.submitForm}
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                  >
                    {t('create')}
                  </LoadingButton>
                </ConditionalRender>
              </CardActions>
            </CardContent>
            <MainDialog
              open={openDeletePopup}
              onCloseClick={() => setOpenDeletePopup(false)}
              onSaveClick={async () => {
                await deleteUser();
                setOpenDeletePopup(false);
                onClose();
              }}
              saveButtonColor="error"
              secondButtonColor="primary"
              modalTitle={t('confirm-delete')}
              description={t('confirm-delete-module-text')}
              buttonText={t('delete')}
            />
          </Card>
        )}
      </Formik>
    </StandardPopup>
  );
};
