import { FormSubmission, StandardPopup } from '@base/web';
import { faCheck, faComment, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, CardContent, IconButton, makeStyles, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@material-ui/core';
import React, {useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { Comments } from './Comments';
import { createEmptyForm } from './components';
import { MinimalReaderItem } from './components/MinimalReaderItem';
import { Form } from './entities/Form';
import { ConditionalSum, getChangeSets } from './Helper';
import { FileProvider } from './provider';
import { MockFileProvider } from './provider/mocks/MockFileProvider';
import { ProvidersProvider } from './provider/ProviderContext';
import { QuestionSpecificChatItem } from './QuestionSpecificChatItem';
export interface FormViewerMinimalCompareProps<T extends FormSubmission> {
  document: Form;
  fileProvider?: FileProvider;
  uploadFileProvider?: FileProvider;
  action?: (questionId: string, action: 'rejected' | 'accepted', federationId: string) => void;
  values?: T[];
  renderHeader(data: T, index: number): React.ReactNode;
  renderFooter?(data: T, index: number): React.ReactNode;
  chats?: { [federationId: string]: QuestionSpecificChatItem[] };
  onSendChat?: (message: string, questionId: string, federationId: string) => void;
  readChatItems?: (chatItemIds: string[], federationId: string) => void;
  contextList?: any;
  readOnly: boolean;
}
const useStyles = makeStyles({
  parent: {
    position: 'relative',
    '&:hover': {
      '& $actions': {
        opacity: 1,
      },
    },
  },
  actions: {
    opacity: 0,
    position: 'absolute',
    right: 8,
    top: 8,
    transition: 'opacity 300ms ease',
    zIndex: 999,
  },
});

export function FormViewerMinimalCompare<T extends FormSubmission>({
  document = createEmptyForm(),
  fileProvider = new MockFileProvider(),
  uploadFileProvider = new MockFileProvider(),
  values = [],
  renderFooter,
  action,
  renderHeader,
  chats = {},
  onSendChat,
  readChatItems,
  contextList,
  readOnly
}: FormViewerMinimalCompareProps<T>) {
  const items = document.content;
  const theme = useTheme();
  const [selectedQuestion, setSelectedQuestion] = useState<{ questionId: string; federationId: string }>(null);
  const me = useSelector((state: Core.StateType) => state.auth.user);

  const classes = useStyles();
  return (
    <ProvidersProvider value={{ fileProvider: fileProvider, uploadFileProvider }}>
      <TableContainer component={Paper} sx={{ maxWidth: '100vw' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width={300} sx={{ minWidth: 300, borderBottom: `2px solid ${theme.palette.primary.main}` }}>
                Question
              </TableCell>
              {values.map((v, idx) => (
                <TableCell width={600} key={v.id} sx={{ borderBottom: `2px solid ${theme.palette.primary.main}` }}>
                  {renderHeader(v, idx)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((i, idx) => {
              switch (i.type) {
                case 'info':
                  return null;
                case 'provide-file':
                  return null;
                case 'section':
                  return (
                    <TableRow key={i.id}>
                      <TableCell component="th" sx={{ paddingLeft: 2, borderBottom: '2px solid #474747', borderTop: '2px solid #474747' }} scope="row" colSpan={1000}>
                        <Typography variant="subtitle1" fontWeight="600">
                          {i.title}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                default: {
                  return (
                    <TableRow key={i.id} sx={{ '&:hover': { bgcolor: 'background.gray' } }} >
                      <TableCell component="th" scope="row">
                        <Typography style={{ wordBreak: 'break-word' }} variant="sub3" fontWeight="500">
                          {i.title}
                        </Typography>
                        <ConditionalSum item={i} values={values.map((v) => v.data[i.id])} />
                      </TableCell>
                      {values.map((v, idx) => {
                        const questionChats = (chats[v.entityId] ?? []).filter((c) => c.questionId === i.id);
                        const unreadCount = questionChats.reduce((sum, c) => sum + (c.read[me.id] ? 0 : 1), 0);
                        // const changeSets = getChangeSets(formSubmissionHistory, i.id).map((c) => ({ ...c, schema: i }));
                        const review = v.review ?? {};
                        const accepted = review[i.id]?.type === 'accepted';
                        const rejected = review[i.id]?.type === 'rejected';
                        const borderColor = accepted ? theme.palette.success.main : questionChats.length > 0 || rejected ? theme.palette.error.main : undefined;
                        const border = borderColor ? '4px solid' : undefined;
                        return (
                          <TableCell
                            className={classes.parent}
                            key={idx}
                            sx={{
                              bgcolor: idx % 2 === 0 ? theme.palette.grey.A200 : undefined,
                              borderLeft: border,
                              borderLeftColor: borderColor,
                            }}
                          >
                            <Badge key={idx} color="error" badgeContent={unreadCount} sx={{ display: 'block', marginRight: 1 }} />
                            <Typography variant="sub3" sx={{ wordBreak: 'break-word', marginBottom: 1 }}>
                              <MinimalReaderItem
                                schemaValue={i}
                                value={v.data[i.id]}
                                context={contextList && contextList.length > idx
                                  ? contextList[idx]
                                  : undefined
                                }
                              />
                            </Typography>
                            {action && (
                              <Stack direction="row" spacing={0.5} className={classes.actions}>
                                <IconButton size="small" sx={{
                                  color: 'white',
                                  bgcolor: 'info.main',
                                  ':hover': {bgcolor: 'info.light', color: 'white'},
                                  width: 24,
                                  height: 24,
                                  fontSize: 15
                                }} onClick={() => {
                                  setSelectedQuestion({federationId: v.entityId, questionId: i.id})
                                }}>
                                  <FontAwesomeIcon fixedWidth icon={faComment} />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  sx={{ color: 'white', bgcolor: 'error.main', ':hover': { bgcolor: 'error.light', color: 'white' }, width: 24, height: 24, fontSize: 15 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    action(i.id, 'rejected', v.entityId);
                                  }}
                                >
                                  <FontAwesomeIcon fixedWidth icon={faTimes} />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  sx={{ color: 'white', bgcolor: 'success.main', ':hover': { bgcolor: 'success.light', color: 'white' }, width: 24, height: 24, fontSize: 15 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    action(i.id, 'accepted', v.entityId);
                                  }}
                                >
                                  <FontAwesomeIcon fixedWidth icon={faCheck} />
                                </IconButton>
                              </Stack>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }
              }
            })}
            {renderFooter && (
              <TableRow>
                <TableCell></TableCell>
                {values.map((v, idx) => (
                  <TableCell key={v.id}>{renderFooter(v, idx)}</TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <StandardPopup visible={Boolean(selectedQuestion)} onBackdropClick={() => setSelectedQuestion(null)} width={800}>
        {Boolean(selectedQuestion) && (
          <Card>
            <CardContent>
              <Typography variant="h1" sx={{ marginBottom: 3, display: 'block' }} fontWeight="500">
                Comment Application
              </Typography>
              <Typography variant="overline" sx={{ marginBottom: 2, display: 'block' }}>
                Mentioned answer
              </Typography>
              <Card sx={{ borderBottom: '4px solid ' + theme.palette.info.main, borderRadius: 1 }}>
                <Typography variant="subtitle1" sx={{ padding: 2 }}>
                  Question: {items.find((i) => i.id === selectedQuestion.questionId)?.title}
                </Typography>
                {/* <Typography>Applicant answer: {values[selectedQuestion]}</Typography> */}
              </Card>
              <Comments
                full
                containerProps={{
                  // height: '500px',
                  maxHeight: '500px',
                  padding: 1,
                  marginTop: 3,
                  // paddingRight: 0,
                  flex: 1,
                  boxSizing: 'border-box',
                }}
                chats={(chats[selectedQuestion.federationId] ?? []).filter((c) => c.questionId === selectedQuestion.questionId)}
                onSend={(m) => onSendChat(m, selectedQuestion.questionId, selectedQuestion.federationId)}
                readChatItems={(items) => readChatItems(items, selectedQuestion.federationId)}
                writeMessageContainerProps={{ sx: { bgcolor: 'grey.300', paddingX: 5, paddingBottom: 4, paddingTop: 3 }, style: { marginLeft: -40, marginRight: -40, marginBottom: -40 } }}
                readOnly={readOnly}
              />
            </CardContent>
          </Card>
        )}
      </StandardPopup>
    </ProvidersProvider>
  );
}
