import { FormSubmission, IFormService, SigningParticipantType } from '@base/web';
import { AdobeSignService } from '@base/firebase';
import firebase from 'firebase';

export class AdobeSignInteractor {
  constructor(private adobeSignService: AdobeSignService, private formService: IFormService) {}

  isUserAuthenticated(): boolean {
    return this.adobeSignService.checkIfUserAuthenticated();
  }

  async processAuthResponse(code: string, redirectUrl: string, uid: string): Promise<void> {
    await this.adobeSignService.processAuthCode(code, redirectUrl, uid);
  }

  async tryInitApiSilently(uid: string): Promise<void> {
    try {
      await this.adobeSignService.getOAuthToken(uid);
      await this.adobeSignService.getApiEndpoint();
    } catch (e) {
      console.error(e);
    }
  }

  async initApiAccess(redirectUrl, uid, state?: string): Promise<void> {
    try {
      await this.adobeSignService.getOAuthToken(uid);
      await this.adobeSignService.getApiEndpoint();
    } catch (e) {
      console.error(e);
      try {
        await this.adobeSignService.redirectUserToLogin(redirectUrl, uid, state);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async createAndSendAgreement(
    uid: string,
    file: File,
    recipients: SigningParticipantType[],
    deadline: Date,
    agreementName: string,
    campaignId: string,
    formId: string,
    storageId: string,
    sendDraft: boolean
  ): Promise<void> {
    if (!this.adobeSignService.checkIfUserAuthenticated()) {
      await this.adobeSignService.getOAuthToken(uid);
      this.adobeSignService.checkIfUserAuthenticated();
    }
    // upload the document to the adobe sign cloud
    const transientDocId = await this.adobeSignService.uploadTransientDocument(file);
    // log the transient document id
    const externalId = campaignId + '-' + formId + '-' + storageId;
    // create an agreement and send it to the recipients by setting the status to "OUT_FOR_SIGNATURE"

    const agreementId = await this.adobeSignService.sendUploadedDocumentToSign({
      docId: transientDocId,
      name: agreementName,
      recipients: recipients,
      externalId: externalId,
      deadline: deadline,
      sendDraft: sendDraft,
    });
    // update the form submission with the agreement id and agreement metadata

    const updatedFormSubmission = await this.formService.setAgreement(campaignId, formId, storageId, agreementId, {
      agreementName,
      status: sendDraft ? 'AUTHORING' : 'OUT_FOR_SIGNATURE',
      deadline: deadline,
      fileName: file.name,
      createdDate: firebase.firestore.Timestamp.now(),
      downloadUrl: await this.adobeSignService.getSignedDocDownloadUrl(agreementId)
    });
  }

  async abortAgreement(agreementId: string, formSubmission: FormSubmission): Promise<void> {
    try {
      await this.adobeSignService.abortAgreement(agreementId);
    } catch (e) {
      console.warn(e);
    }
    await this.formService.deleteAgreement(formSubmission.campaignId, formSubmission.formId, formSubmission.entityId, agreementId);
  }

  async downloadAgreement(agreementId: string, agreementName: string): Promise<void> {
    const url = await this.adobeSignService.getSignedDocDownloadUrl(agreementId);
    console.log('downloadAgreement: [agreementId, agreementName, url]', agreementId, agreementName, url);
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = url;
    link.download = agreementName.replace(/\s/g, '_') + '_signed.pdf';
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }
}
