import { API, ESignAgreement, FormSubmission } from '@base/core';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { useDeleteESignAgreementMutation } from '../hooks';
import { Button, Stack, Tooltip } from '@material-ui/core';
import { ActionDialog } from '../../../components/common/popups/ActionDialog';
import { LoadingPromiseButton } from '@base/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faFileDownload } from '@fortawesome/pro-solid-svg-icons';

export function ESignControls({ form, agreement }: { form: FormSubmission; agreement: ESignAgreement }) {
  const { t } = useTranslation();
  const [abortCheckOpen, setAbortCheckOpen] = useState(false);
  const deleteAgreementMutation = useDeleteESignAgreementMutation();

  const isSignedIn = API.adobeSign.isUserAuthenticated();

  const abortAgreement = useCallback(async () => {
    await deleteAgreementMutation.mutateAsync({ agreementId: agreement.agreementId, formSubmission: form });
  }, [agreement.agreementId, deleteAgreementMutation, form]);

  const openConsole = useCallback(() => {
    window.open(`https://secure.eu1.adobesign.com/public/agreements/view/${agreement.agreementId}`);
  }, [agreement.agreementId]);

  const downloadAgreement = useCallback(async () => {
    if (!agreement.downloadUrl) return;
    // download the agreement from the downloadUrl
    const response = await fetch(agreement.downloadUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = agreement.agreementName;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }, [agreement.downloadUrl, agreement.agreementName]);

  return (
    <Stack style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      <ActionDialog open={abortCheckOpen} onCloseClick={() => setAbortCheckOpen(false)} modalTitle={'areYouShure'} onSaveClick={abortAgreement}>
        {t('thisCantBeReversed')}
      </ActionDialog>
      {isSignedIn && (
        <Tooltip title={'Manage and sign agreements' ?? t('agreementSigningAndManaging')}>
          <Button variant={'contained'} color={'success'} startIcon={<FontAwesomeIcon icon={faExternalLink} style={{ height: 14, width: 14 }} />} size={'small'} onClick={openConsole}>
            {/*{t('openAdobeConsole')}*/}Open Adobe console
          </Button>
        </Tooltip>
      )}
      <Stack spacing={1} direction={'row'}>
        {
          isSignedIn && agreement.status != 'CANCELLED' && agreement.status != 'SIGNED' && (
            <Tooltip title={'Stop the signing process' ?? t('stopTheSigningAndDeleteTheAgreement')}>
              <LoadingPromiseButton variant={'outlined'} color={'error'} size={'small'} onClick={abortAgreement}>
                {t('abort')}
              </LoadingPromiseButton>
            </Tooltip>
          ) /*: (*/
          /*<Tooltip title={t('stopTheSigningAndDeleteTheAgreement')}>
            <LoadingPromiseButton variant={'outlined'} color={'error'} size={'small'} onClick={abortAgreement} sx={{ minWidth: 38 }}>
              <FontAwesomeIcon icon={faTrash} style={{ height: 16, width: 16 }} />
            </LoadingPromiseButton>
          </Tooltip>*/
        }
        {agreement.downloadUrl && (
          <Tooltip title={'Download the signed document' ?? t('downloadSignedDocument')}>
            <LoadingPromiseButton variant={'contained'} onClick={downloadAgreement} size={'small'} startIcon={<FontAwesomeIcon icon={faFileDownload} style={{ height: 16, width: 16 }} />}>
              {t('download')}
            </LoadingPromiseButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
