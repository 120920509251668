import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faFolder, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { StandardPopup, usePopup } from '../../components/common';
import { cx } from '../../lib';
import { useGetChildren as useCampaignChildren, useGetFile as useGetCampaignFile, useGetForms } from '../campaign/hooks';
import './FolderSelectPopup.scss';
import { useGetChildren, useGetFile } from './hooks';
import { useAutoUpdateState } from './useAutoUpdateState';
import { BiathlonYearPicker } from "../campaign/settings/BiathlonYearPicker";
import { getCurrentBiathlonYear } from "../campaign/getCurrentBiathlonYear";

export interface FolderSelectPopupProps {
  initialParent?: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onFolderSelected: (folderId: string, parent?: string) => void;
  excludeFolderIds?: string[];
  resetOnVisible?: boolean;
  mimeTypes?: string[];
  showYearPicker?: boolean;
}

export function FolderSelectPopup({ initialParent, visible, setVisible, onFolderSelected, excludeFolderIds = [], resetOnVisible = false, mimeTypes, showYearPicker }: FolderSelectPopupProps) {
  const [currentParent, setCurrentParent] = useAutoUpdateState(initialParent);
  const { data: childObj } = useGetChildren(currentParent);
  const { data: parent } = useGetFile(currentParent);
  return renderSelectPopup(resetOnVisible, visible, setCurrentParent, initialParent, childObj, excludeFolderIds, currentParent, setVisible, onFolderSelected, parent, mimeTypes, showYearPicker);
}

export function CampaignFolderSelectPopup({ initialParent, visible, setVisible, onFolderSelected, excludeFolderIds = [], resetOnVisible = false, mimeTypes, showYearPicker }: FolderSelectPopupProps) {
  const [currentParent, setCurrentParent] = useAutoUpdateState(initialParent);
  const { data: childObj = { children: [], fileCount: 0, folderCount: 0 } } = useCampaignChildren(currentParent);
  const { data: forms = [] } = useGetForms(currentParent);
  const { data: parent } = useGetCampaignFile(currentParent);
  const obj: { children: Core.VirtualFile[]; fileCount: number; folderCount: number } = {
    children: [...childObj.children, ...forms.filter((f) => !f.parent).map((f) => ({ ...f, type: 'form' } as Core.VirtualFile))],
    fileCount: childObj.fileCount + forms.length,
    folderCount: childObj.folderCount,
  };
  return renderSelectPopup(resetOnVisible, visible, setCurrentParent, initialParent, obj, excludeFolderIds, currentParent, setVisible, onFolderSelected, parent, mimeTypes, showYearPicker);
}

function renderSelectPopup(
  resetOnVisible: boolean,
  visible: boolean,
  setCurrentParent: React.Dispatch<React.SetStateAction<string>>,
  initialParent: string,
  childObj: { children: Core.VirtualFile[]; fileCount: number; folderCount: number },
  excludeFolderIds: string[],
  currentParent: string,
  setVisible: (visible: boolean) => void,
  onFolderSelected: (folderId: string, parent?: string) => void,
  parent: Core.VirtualFile,
  mimeTypes?: string[],
  showYearPicker?: boolean
) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [seasonValue, setSeasonValue] = useState<string>(getCurrentBiathlonYear());
  const showNotification = usePopup();
  const { t } = useTranslation();
  let folders = [];

  if (childObj?.children[0]?.type === 'campaign') {
    folders = childObj.children
      .filter((f) => f.biathlonYear === seasonValue)
      .filter((f) => !excludeFolderIds.some((id) => f.id == id))
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  } else {
    folders = childObj?.children
      .filter((f) => f.type === 'folder' || f.type === 'campaign' || (mimeTypes && mimeTypes.includes(f.type)))
      .filter((f) => !excludeFolderIds.some((id) => f.id == id))
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  }

  useEffect(() => {
    if (resetOnVisible && visible) setCurrentParent(initialParent);
  }, [visible]);

  useMemo(() => {
    if (childObj?.children[0]?.type === 'campaign') {
      folders = childObj.children
        .filter((f) => f.biathlonYear === seasonValue)
        .filter((f) => !excludeFolderIds.some((id) => f.id == id))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    } else {
      folders = childObj?.children
        .filter((f) => f.type === 'folder' || f.type === 'campaign' || (mimeTypes && mimeTypes.includes(f.type)))
        .filter((f) => !excludeFolderIds.some((id) => f.id == id))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    }
  }, [seasonValue]);

  const isCurrentSelectable =
    (folders.length > 0 && selectedIndex != -1 && (!mimeTypes || mimeTypes?.includes(folders[selectedIndex]?.type))) || (currentParent != null && (!mimeTypes || mimeTypes?.includes(parent?.type)));

  const handleSelectPress = () => {
    if (isCurrentSelectable) {
      setVisible(false);
      if (folders.length > 0 && selectedIndex != -1) onFolderSelected(folders[selectedIndex].id, currentParent);
      else onFolderSelected(currentParent);
    } else {
      showNotification({
        title: 'Please select a Folder first!',
        type: 'error',
        time: 1500,
      });
    }
  };
  return (
    <StandardPopup visible={visible} onBackdropClick={() => setVisible(false)}>
      <div className="folder-select-popup-container">
        <div className="navbar-container">
          {parent && (
            <div className="navbar-content">
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ marginRight: 10 }}
                onClick={() => {
                  setCurrentParent(parent.parent);
                  setSelectedIndex(-1);
                }}
              />
              <div
                style={{ flex: 1 }}
                onClick={() => {
                  setCurrentParent(parent.parent);
                  setSelectedIndex(-1);
                }}
              >
                {parent.name}
              </div>
              <div style={{ marginRight: 10 }}>
                { showYearPicker && childObj?.children[0]?.type === 'campaign' &&
                  <BiathlonYearPicker
                    sx={{width: 150}}
                    size="small"
                    value={seasonValue}
                    onChange={(e) => {
                      setSeasonValue(e.target.value as string);
                    }}
                  />
                }
              </div>
              <div
                className="red-rounded-button"
                onClick={() => {
                  setVisible(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} color="white" />
              </div>
            </div>
          )}
          {!parent && <div>{t('areas')}</div>}
        </div>
        <div className="content-container">
          <div className="files-container">
            {folders.map((c, i) => {
              return (
                <div
                  className={cx('file-container', i === selectedIndex && 'active')}
                  key={c.id}
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                    if (mimeTypes.includes(c.type) && !mimeTypes.includes('folder')) return;
                    setCurrentParent(c.id);
                  }}
                  onClick={() => mimeTypes.includes(c.type) ? setSelectedIndex(i) : () => {}}
                >
                  <FontAwesomeIcon icon={faFolder} style={{ marginRight: 10 }} />
                  <p>{c.name}</p>
                </div>
              );
            })}
          </div>
          <div className="button-row">
            <button
              onClick={() => {
                setVisible(false);
              }}
              style={{ marginRight: 10 }}
            >
              {t('cancel')}
            </button>
            <button disabled={!isCurrentSelectable} onClick={handleSelectPress} className="standard-button">
              {t('move-here')}
            </button>
          </div>
        </div>
      </div>
    </StandardPopup>
  );
}
