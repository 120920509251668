import cuid from 'cuid';
import {IUserService, Query, UserCreationData} from '../entities';
import type * as admin from 'firebase-admin';

export class UsersInteractor {
  constructor(private userService: IUserService) {}
  setProfileImage(uid: string, data: Uint8Array | Blob | ArrayBuffer): Promise<string> {
    return this.userService.setProfileImage(uid, data);
  }

  impersonateUser(uid: string): Promise<void> {
    return this.userService.impersonateUser(uid);
  }

  updateUserGroups(uid: string, groups: { add: string[]; remove: string[] }): Promise<void> {
    return this.userService.updateUserGroups(uid, groups);
  }
  setUserRoles(uid: string, roles: Core.Roles): Promise<void> {
    return this.userService.setUserRoles(uid, roles);
  }
  setUserRights(uid: string, rights: Core.Rights): Promise<void> {
    return this.userService.setUserRights(uid, rights);
  }
  updateUser(uid: string, userData: Partial<Omit<Core.User & { password: string }, 'groups' | 'rights' | 'roles' | 'id'>>): Promise<void> {
    return this.userService.updateUser(uid, userData);
  }
  getUserById(uid: string): Promise<admin.auth.UserRecord & Core.User> {
    return this.userService.getUserById(uid);
  }
  deleteUser(uid: string): Promise<void> {
    return this.userService.deleteUser(uid);
  }
  createUserQuery(): Promise<Query<Core.User>> {
    return this.userService.createUserQuery();
  }
  async createUser(userData: UserCreationData): Promise<Core.User> {
    if (!userData.password) {
      userData.password = cuid().slice(0, 16);
    }
    return await this.userService.createUser(userData);
  }

  async getUsersByGroupId(groupId: string): Promise<Core.User[]> {
    const query = await this.createUserQuery();
    const users = await query.getNextPage(1000);
    return users.filter((u) => u.groups[groupId]);
  }

  async setExternalUserRights(uid: string, rights: Core.GroupRights): Promise<void> {
    return await this.userService.setExternalUserRights(uid, rights);
  }

  async updateExternalUser(uid: string, userData: Partial<Omit<Core.User, 'groups' | 'rights' | 'roles' | 'id' | 'photoUrl'> & { password: string }>): Promise<void> {
    return await this.userService.updateExternalUser(uid, userData);
  }

  async deleteExternalUser(uid: string): Promise<void> {
    return await this.userService.deleteExternalUser(uid);
  }

  async createExternalUser(userData: UserCreationData, group: string): Promise<Core.User> {
    if (!userData.password) {
      userData.password = cuid().slice(0, 16);
    }
    return await this.userService.createExternalUser(userData, group);
  }
}
