import { Box, Divider, Stack, Typography, useTheme } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { createEmptyForm } from './components';
import { MinimalReaderItem } from './components/MinimalReaderItem';
import './EditorsFormEditor.module.scss';
import { Form } from './entities/Form';
import { FileProvider } from './provider/FileProvider';
import { MockFileProvider } from './provider/mocks/MockFileProvider';
import { ProvidersProvider } from './provider/ProviderContext';
import { useReactToPrint } from 'react-to-print';
import { useSettings } from 'libs/base/web/src/theme/ThemeProvider';
import { PdfFooter, PdfHeader } from '../../../../base/web/src/assets/images';
import { ApplicationForm } from '../../../../base/core/src';
import {VOID} from "./FormViewer";
import {InformationReader} from "./components/InformationReader";

export interface FormViewerPDFProps<T> {
  document: Form;
  fileProvider?: FileProvider;
  uploadFileProvider?: FileProvider;
  value: T;
  showInformationFields: boolean;
}

function FormViewer<T extends { data: any; id: string; submitterName?: string; submissionDate?: string; lastSubmitterName?: string; lastSubmissionDate?: string }>(
  { document = createEmptyForm(), fileProvider = new MockFileProvider(), uploadFileProvider = new MockFileProvider(), value, showInformationFields = true }: FormViewerPDFProps<T>,
  ref
) {
  const items = document.content;

  const isReport = Boolean((document as ApplicationForm).parent);
  const pdfRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: document.name,
    pageStyle: `
    @media all {
      .page-break {
        display: none;
      }
    }

    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break{
        display: block;
        margin-top: 1rem;
      }
      .page-break-header {
        page-break-before: auto;
        page-break-after: avoid;
      }
      .page-break-body {
        page-break-before: auto;
        page-break-inside: avoid;
        page-break-after: auto;
      }
    }

    @page {
      size: auto;
      margin-top: 10mm;
      margin-bottom: 15mm;
    }`,
  });

  const theme = useTheme();
  const settings = useSettings();

  useImperativeHandle(ref, () => ({
    print: handlePrint,
  }));

  return ( value.data &&
    <ProvidersProvider value={{ fileProvider: fileProvider, uploadFileProvider }}>
      <Box visibility="hidden" height="0" position="absolute">
        <Stack ref={pdfRef} spacing={2}>
          <img src={PdfHeader} alt="IBU document header" style={{ width: '100%', objectFit: 'contain' }} />
          <Box paddingX="7mm">
            <Typography variant="h4" fontWeight="400" color={theme.palette.primary.main}>
              {isReport ? 'Report' : 'Application'}: {document.name}
            </Typography>
            <Typography fontWeight="400" variant="sub3" sx={{ mt: 0 }} style={{ marginTop: 2 }} color={theme.palette.primary.main}>
              {document.description}
            </Typography>
            {items.map((i, idx) => {
              switch (i.type) {
                case 'info':
                  return ( showInformationFields &&
                      <React.Fragment key={i.id}>
                        <div className="page-break page-break-header">
                          <InformationReader key={i.id} value={i} focused={false} setFocus={VOID} />;
                        </div>
                      </React.Fragment>
                  );
                case 'provide-file':
                case 'request-file':
                  return null;
                case 'section':
                  return (
                    <React.Fragment key={i.id}>
                      <div className="page-break page-break-header">
                        <Typography sx={{ paddingY: 1, paddingX: 2, backgroundColor: '#E5F2F8', color: theme.palette.primary.main, borderRadius: 1, mb: 2, mt: 2 }}>{i.title}</Typography>
                      </div>
                    </React.Fragment>
                  );
                default: {
                  return (
                    <div className="page-break page-break-body">
                      <Stack key={i.id} spacing={2} mt={2} sx={{ pageBreakInside: 'avoid', breakInside: 'avoid', display: 'block', position: 'relative', pageBreakAfter: 'auto' }}>
                        <Typography sx={{ color: 'black' }} fontWeight="400">
                          {i.title}
                        </Typography>
                        <Typography variant="sub3" sx={{ wordBreak: 'break-word', marginBottom: 1, display: 'block' }} style={{ marginTop: 2 }}>
                          <MinimalReaderItem schemaValue={i} value={value.data[i.id]} context={(
                            { type: 'print' }
                          )}/>
                        </Typography>
                        <Divider />
                      </Stack>
                    </div>
                  );
                }
              }
            })}
            <Stack>
              {value?.submitterName && (
                <Typography variant="caption">
                  Submitted by: {value?.submitterName} {value?.submissionDate && `- on ${value?.submissionDate}`}
                </Typography>
              )}
              {value?.lastSubmitterName && (
                <Typography variant="caption">
                  Last updated by: {value?.lastSubmitterName} {value?.lastSubmissionDate && `- on ${value?.lastSubmissionDate}`}
                </Typography>
              )}
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingX: '7mm',
              paddingY: 1,
              justifyContent: 'space-between',
            }}
            height="100mm"
            className="page-break page-break-body"
          >
            <Box>
              <img src={PdfFooter} alt="IBU document footer" style={{ width: '100%', objectFit: 'contain' }} />
              <Typography variant="sub3" fontWeight="500" lineHeight={1.4} color="grey">
                INTERNATIONAL BIATHLON UNION
              </Typography>
              <Typography variant="subtitle2" fontSize={13} lineHeight={1.4} color="grey">
                Sonystr. 20 / A-5081 Anif (Salzburg) / AUSTRIA
              </Typography>
              <Typography variant="subtitle2" fontSize={13} lineHeight={1.4} color="grey">
                Tel: +43 - 6 62 - 85 50 50 / Fax: +43 - 6 62 - 855 05 08
              </Typography>
              <Typography variant="subtitle2" fontSize={13} lineHeight={1.4} color="grey">
                Biathlon@ibu.at / www.biathlonworld.com
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Box>
    </ProvidersProvider>
  );
}

export const FormViewerPDF = forwardRef<{ print: () => void }, FormViewerPDFProps<any>>(FormViewer);
